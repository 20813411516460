import React from 'react';

interface CheckerBannerProps {
  number?: string;
  text: React.ReactNode;
  value: string;
  onChange: (number: string | undefined, text: string, isChecked: boolean) => void;
}

const CheckerBannerComponent: React.FC<CheckerBannerProps> = ({ number, text, value, onChange }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(number, value, event.target.checked);
  };

  return (
    <div className="form-check accordeon--checker">
      <input
        className="form-check-input"
        type="checkbox"
        id={`checkbox-${number}`}
        onChange={handleCheckboxChange}
        value={value}
      />
      <div className="form-check-input__back" />
      <label className="form-check-label" htmlFor={`checkbox-${number}`}>
        {number && <span className="accordeon-number">{number}.</span>}
        <span className="accordeon-text text-start">{text}</span>
      </label>
    </div>
  );
};

export default CheckerBannerComponent;
