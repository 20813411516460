import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import CheckerBannerComponent from '../../../../Form/CheckerBanner/checkerBanner.component';


const CausalCard1Component: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [causalData, setCausalData] = useState({
    number: '',
    text: '',
    isChecked: false
  });

  const handleCausalChange = (number: string | undefined, text: string, isChecked: boolean) => {
    const actualNumber = number || ''; // Usar cadena vacía si number es undefined
    setCausalData({ number: actualNumber, text, isChecked });
  };
  
  

  return (
    <div className="causal-card">
        <h3 className="text-regular">Causal 1</h3>
        <article>
          <p>La primera causal de la Ley N° 21.030 señala que se autoriza la interrupción voluntaria del embarazo cuando la mujer se encuentre en riesgo vital, de modo que la interrupción del embarazo evite un peligro para su vida. Sin límites gestacionales.</p>
        </article>
        <Accordion className="card-accordeon">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Listado de patologías</Accordion.Header>
            <Accordion.Body>
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="1"
                text={<span>Embarazo ectópico.</span>}
                value="Embarazo ectópico"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="2"
                text={<span><strong>Embarazo no viable: </strong>Muerte fetal, no embrionarios.</span>}
                value="Embarazo no viable: Muerte fetal, no embrionarios"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="3"
                text={<span>Neoplasia del embarazo.</span>}
                value="Neoplasia del embarazo"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="4"
                text={<span><strong>Patología o accidentes del embarazo:</strong> Infección intrauterina, preeclampsia severa, eclampsia, rotura uterina. </span>}
                value="Patología o accidentes del embarazo: Infección intrauterina, preeclampsia severa, eclampsia, rotura uterina"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="5"
                text={<span>Insuficiencia cardíaca congestiva con capacidad funcional lll y lV por cardiopatía congénita o adquirida, hipertensión arterial refractaria a tratamiento, enfermedad hipertensiva severa con daños en órganos blanco, cardiopatía isquémica refractaria a tratamiento, hipertensión pulmonar, síndrome de Eisenmenger, historia de infarto del miocardio, historia de cardiomiopatia del embarazo.</span>}
                value="Insuficiencia cardíaca congestiva con capacidad funcional lll y lV por cardiopatía congénita o adquirida, hipertensión arterial refractaria a tratamiento, enfermedad hipertensiva severa con daños en órganos blanco, cardiopatía isquémica refractaria a tratamiento, hipertensión pulmonar, síndrome de Eisenmenger, historia de infarto del miocardio, historia de cardiomiopatia del embarazo"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="6"
                text={<span><strong>Enfermedad neurológica:</strong> aneurisma o malformación arteriovenosa.</span>}
                value="Enfermedad neurológica: aneurisma o malformación arteriovenosa"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="7"
                text={<span>Lupus eritematoso con daño renal severo refractario a tratamiento.</span>}
                value="Lupus eritematoso con daño renal severo refractario a tratamiento"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="8"
                text={<span>Neoplasia maligna, requiere quimioterapia, radioterapia o tratamiento quirúrgico.</span>}
                value="Neoplasia maligna, requiere quimioterapia, radioterapia o tratamiento quirúrgico"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="9"
                text={<span>Insuficiencia respiratoria severa.</span>}
                value="Insuficiencia respiratoria severa"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="10"
                text={<span>Insuficiencia hepática severa.</span>}
                value="Insuficiencia hepática severa"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="11"
                text={<span><strong>Enfermedad genética:</strong> Síndrome de Marfán.</span>}
                value="Enfermedad genética: Síndrome de Marfán"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="12"
                text={<span><strong>Enfermedad hematológica:</strong> púrpura trombocitopénico trombótico.</span>}
                value="Enfermedad hematológica: púrpura trombocitopénico trombótico"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="13"
                text={<span>Enfermedad renal deterioro de la función renal en el embarazo temprano.</span>}
                value="Enfermedad renal deterioro de la función renal en el embarazo temprano"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="14"
                text={<span><strong>Patologías Neoplásica:</strong> carcinoma invasivo del cuello uterino. Cualquier enfermedad Neoplásica en la cual la sobrevivencia de la mujer dependa de un tratamiento oportuno con quimioterapia o radiación en dosis teratogénica.</span>}
                value="Patologías Neoplásica: carcinoma invasivo del cuello uterino. Cualquier enfermedad Neoplásica en la cual la sobrevivencia de la mujer dependa de un tratamiento oportuno con quimioterapia o radiación en dosis teratogénica"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="15"
                text={<span><strong>Enfermedad terminal:</strong> etapa final de cáncer o SIDA.</span>}
                value="Enfermedad terminal: etapa final de cáncer o SIDA"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="16"
                text={<span>Cualquier patología de la mujer que ponga en riesgo la vida de la gestante debidamente fundamentada (ideación suicida).</span>}
                value="Cualquier patología de la mujer que ponga en riesgo la vida de la gestante debidamente fundamentada (ideación suicida)"
              />
              
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
    </div>
  );
};

export default CausalCard1Component;
