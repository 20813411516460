import React, { useState } from 'react';
import HeaderShared from '../../../../shared/header.shared';
import PrivacyComponent from '../../../../components/Banners/Privacy/privacy.component';
import StepperComponent from '../../../../components/Stepper/stepper.component';
import FooterShared from '../../../../shared/footer.shared';
import CodePhoneComponent from '../../../../components/Auth/ActiveCode/codePhone/codePhone.component';

const CodePhoneScreen = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeStep, setActiveStep] = useState(1); // Estado para el paso actual
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
              <StepperComponent activeStep={activeStep} />
              </div>
              <div className="col-lg-5 col-12">
                <h1 className="mb-4">Código de activación</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-icon">
                      <i className="fa-brands fa-whatsapp"></i> Te acabamos de
                      enviar el código por SMS a tu número con terminación:
                    </p>
                    <p className="text-24">
                      <strong>
                        <span className="text-hide">
                          <i className="fa-solid fa-circle"></i>
                          <i className="fa-solid fa-circle"></i>
                          <i className="fa-solid fa-circle"></i>
                          <i className="fa-solid fa-circle"></i>
                        </span>
                        12 12
                      </strong>
                    </p>
                </article>
                <CodePhoneComponent />
              </div>
              <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default CodePhoneScreen;
