import React, {useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import { Alert } from 'react-bootstrap';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import SignUpComponent from '../../../components/Auth/SignUp/signUp.component';
import StepperComponent from '../../../components/Stepper/stepper.component';

const SignUpScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeStep, setActiveStep] = useState(1); // Estado para el paso actual

  
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
              <StepperComponent activeStep={activeStep} />
              </div>
              <div className="col-lg-5 col-12">
                <h1>Vamos a crear tu cuenta</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-green350">
                    Quédate tranquila, tus datos siempre están protegidos.
                  </p>
                  <p>
                    Recuerda que tienes derecho a un procedimiento seguro,
                    informado y libre de prejuicios.
                  </p>
                </article>
                <Alert variant="success" className="alert--soft-success">
                  <i className="icon fa-regular fa-flag"></i>
                  <article>
                    <p>
                      Si no estás en Chile, te sugerimos busques información
                      sobre <strong>Redes de Acompañamiento Seguro</strong> y acompañamiento
                      <strong>feminista</strong> en el país que estés viviendo actualmente.{' '}
                    </p>
                  </article>
                </Alert>
                <SignUpComponent />
              </div>
              <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SignUpScreen;