import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface DeleteConfirmProps {
  show: boolean;
  onHide: () => void;
}

const DeleteConfirmComponent: React.FC<DeleteConfirmProps> = (props) => {



  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="modal--md"
    >
     
      <Modal.Body className="text-center">
        <div className="d-flex justify-content-center mb-4">
          <figure className="image-64">
            <img src="/assets/images/icons/checked.svg" alt="Confirmación" />
          </figure>
        </div>
       <h2 className="text-40 mb-4">Se ha cerrado tu cuenta</h2>
       <p className="text-24">Tu cuenta y toda la información<br/> fue eliminada de la base de datos.</p>
       <p className="text-24">Si esto es un error deberás <strong>registrarte nuevamente</strong></p>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 text-center mb-4">
          <a
            href="/"
            className="btn btn--type1 btn--180"
          >Salir</a>
        </div>
        <div className="col-12 text-center mb-4">
          <a href="/crear-cuenta"
            className="btn--simple text-purple400"
          >Volver a registrárme</a>
        </div>
       
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmComponent;
