import React, {useState, useEffect} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ocupacionesData from '../../../utils/ocupaciones.json'; 
import nivelEducativoData from '../../../utils/nivel-educativo.json'; 
import puebloOrigen from '../../../utils/pueblo-origen.json'; 
import { useNavigate } from 'react-router-dom';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';

interface NivelEducativo {
  title: string;
  levels: Level[];
}

interface Level {
  id: number;
  label: string; 
}


const SocioCulturalComponent = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ocupaciones, setOcupaciones] = useState(ocupacionesData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ocupacionesSecundarias, setOcupacionesSecundarias] = useState(ocupacionesData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ocupacionesAdicional, setOcupacionesAdicional] = useState(ocupacionesData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nivelEducativo, setNivelEducativo] = useState(nivelEducativoData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pueblo, setPueblo] = useState(puebloOrigen);
  const [mostrarPuebloOrigen, setMostrarPuebloOrigen] = useState(false);
  const [formularioValido, setFormularioValido] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);

  const handlePuebloOriginarioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMostrarPuebloOrigen(e.target.value === 'si');
    verifyFields();
  };

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
          console.log(campoCompleto)
        }
      }
    });
  
    gruposRadio.forEach(nombre => {
      const seleccionado = document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;

      console.log(seleccionado)
    });
  
    setFormularioValido(todosCompletos);
  };
  
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };

  const handleNivelEducativoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(e.target.value, 10); // Convierte el valor a número
    let found = false;
  
    for (const nivel of nivelEducativoData) {
      // Accede directamente a 'nivel.levels' en lugar de 'nivel.level.levels'
      const foundNivel = nivel.levels.find((item: Level) => item.id === selectedId);
      if (foundNivel) {
        // Aquí manejas el nivel educativo encontrado
        found = true;
        break;
      }
    }
  
    if (!found) {
      // Maneja el caso en que no se encuentra el nivel educativo
    }
  
    verifyFields();
  };
  
  
  
  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };


  useEffect(() => {
    verifyFields();
  }, [ocupaciones, nivelEducativo]);

  const handleSubmit = () => {
    navigate('/salud-ginecologica');
  };

  return (
    <>
     <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
          />
       <div className="form-block">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label className="form-label label--icon">
                Ocupación principal <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es importante para nosotras saber cuales son tus ocupaciones principales y secundarias, con el objetivo de conocerte mejor y poder brindarte un  acompañamiento adecuado a tu situación.  Esta información se puede transformar en estadísticas que nos sirve para crear materiales destinados a la despenalización cultural del aborto, donde se demuestra que quienes abortan tienen distintas ocupaciones y son muy diversas.  
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select requiredField"
                aria-label="Tu ocupación"
                defaultValue={'default'}
                name="Ocupacion"
                onChange={() => {
                  verifyFields();
                }}
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {ocupaciones.map((ocupacion) => (
                  <option key={ocupacion.id} value={ocupacion.id}>{ocupacion.label}</option>
                ))}
              </select>
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
              Ocupación secundaria (opcional)
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Tener información sobre la ocupación secundaria y adicional es importante porque las mujeres desarrollamos tareas no reconocidas y/o no remuneradas, que ocupan importante tiempo cada día. Saber cuales  son. Esas actividades nos permite conocerte mejor y brindarte un acompañamiento adecuado a tu situación. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Tu Ocupación secundaria"
                defaultValue={'default'}
                name="OcupacionSecundaria"
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {ocupacionesSecundarias.map((ocupacion) => (
                  <option key={ocupacion.id} value={ocupacion.id}>{ocupacion.label}</option>
                ))}
              </select>
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
              Ocupación adicional (opcional)
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Tener información sobre la ocupación secundaria y adicional es importante porque las mujeres desarrollamos tareas no reconocidas y/o no remuneradas, que ocupan importante tiempo cada día. Saber cuales  son. Esas actividades nos permite conocerte mejor y brindarte un acompañamiento adecuado a tu situación. 

                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Tu ocupación adicional"
                defaultValue={'default'}
                name="OcupacionAdicional"
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {ocupacionesAdicional.map((ocupacion) => (
                  <option key={ocupacion.id} value={ocupacion.id}>{ocupacion.label}</option>
                ))}
              </select>
            </div>

            <div className="form-row">
              <label className="form-label label--icon">
                Tu nivel educativo <small className="required">*</small>
               
              </label>
              <select
                  className="form-select requiredField"
                  aria-label="Tu nivel educativo"
                  defaultValue={'default'}
                  name="NivelEducativo"
                  onChange={handleNivelEducativoChange}
                >
                  <option value="default" disabled>Elige una de las opciones</option>
                  {nivelEducativoData.map((nivel: NivelEducativo) => (
                    <optgroup key={nivel.title} label={nivel.title}>
                      {nivel.levels.map((opcion: Level) => (
                        <option key={opcion.id} value={opcion.id}>{opcion.label}</option>
                      ))}
                    </optgroup>
                  ))}
        </select>
            </div>

            <div className="form-row">
              <label className="form-label label--icon">
                ¿Eres de algún pueblo originario?
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es muy importante para los pueblos originarios el reconocimiento de su existencia y permanencia en nuestra sociedad. <br/>
Es importante para nosotras generar estadísticas generales sobre quiénes abortan.  Estas estadísticas ayudan a despenalizar socialmente a quienes abortan. 

                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="pueblo" id="puebloSi" value="si" onChange={handlePuebloOriginarioChange}/>
                  <label className="form-check-label" htmlFor="puebloSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="pueblo" id="puebloNo" value="no" onChange={handlePuebloOriginarioChange}/>
                  <label className="form-check-label" htmlFor="puebloNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="pueblo" id="PuebloNoSeguro" value="no-estoy-segura" onChange={handlePuebloOriginarioChange}/>
                  <label className="form-check-label" htmlFor="PuebloNoSeguro">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>
            
            {mostrarPuebloOrigen && (
              <div className="form-row">
                <label className="form-label label--icon">
                  Selecciona tu pueblo de origen 
                 
                </label>
                <select
                  className="form-select"
                  aria-label="Tu pueblo origen"
                  defaultValue={'default'}
                  name="PuebloOrigen"
                >
                  <option value="default" disabled>Elige una de las opciones</option>
                  {pueblo.map((ocupacion) => (
                    <option key={ocupacion.codigo} value={ocupacion.codigo}>{ocupacion.nombre}</option>
                  ))}
                </select>
              </div>

            )}
            

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Eres de origen afrodescendiente? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Es muy importante para la comunidad afrodescendiente el reconocimiento de su existencia y presencia en nuestra sociedad. <br/>
Es importante para nosotras generar estadísticas generales sobre quiénes abortan.  Estas estadísticas ayudan a despenalizar socialmente a quienes abortan. 


                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="afrodescendiente" id="afroSi" value="si"  onChange={handleCheckboxChange}/>
                  <label className="form-check-label" htmlFor="afroSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="afrodescendiente" id="afroNo" value="no"  onChange={handleCheckboxChange}/>
                  <label className="form-check-label" htmlFor="afroNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="afrodescendiente" id="afroNoSeguro" value="no-estoy-segura"  onChange={handleCheckboxChange}/>
                  <label className="form-check-label" htmlFor="afroNoSeguro">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Con quién vives? <small className="required">*</small>
               
              </label>
              <input
                type="text"
                className="form-control requiredField"
                id="live"
                name="PersonasCompartir"
                placeholder="Escribe aquí con quién compartes tu vivienda "
                onChange={handleTextInputChange}
                
              />
            </div>
           
            <div className="form-row module-top40 form--last">
              <button
                type="submit"
                className="btn btn--type1"
                disabled={!formularioValido}
              >Guardar y continuar</button>
            </div>
            <div className="form-row form--last">
            <button
                type="button"
                className="btn--danger"
                onClick={() => setModalShow(true)}
              >Borrar mi registro</button>
            </div>
          </form>
        </div>
    </>
  );
}

export default SocioCulturalComponent;