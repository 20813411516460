import React, {useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import StepperComponent from '../../../components/Stepper/stepper.component';
import { Alert } from 'react-bootstrap';
import SocioCulturalComponent from '../../../components/Auth/SocioCultural/socioCultural.component';

const SocioCulturalScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeStep, setActiveStep] = useState(4); // Estado para el paso actual
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div> 
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
              <StepperComponent activeStep={activeStep} />
              </div>
              <div className="col-lg-5 col-12">
                <h1>Contexto Sociocultural</h1>
                <article className="text-20 col-12 col-reset">
                  <p>En este apartado vamos a recoger datos generales sobre ti, a qué te dedicas y otros temas. </p>
                  <p>Esta información  (que será desvinculada de tus datos personales y será usada únicamente para fines estadísticos), nos permite conocer los perfiles de las mujeres que abortan y su diversidad.</p>
                  <p>Con esto, queremos contribuir a eliminar prejuicios sobre quienes ejercen su derecho a decidir. Recuerda que todas somos distintas y todas podemos abortar durante todo nuestro periodo reproductivo.</p>
                  <p className="text-Green350">Quédate tranquila, tus datos siempre están protegidos.</p>
                </article>
                <Alert variant='success' className="alert--soft-success alert--complete">
                  <p className="text-base mb-2">¿Cuál es tu principal ocupación o cuales son tus principales ocupaciones, si es que tienes más de una?</p>
                  <p className="text-base mb-4"><strong>Puedes enlistar hasta 3</strong>, poniendo en <strong>primer lugar a la más importante</strong> y en tercer lugar a la menos importante. </p>
                  <div className="col-12">
                  <i className="icon fa-regular fa-heart"></i>
                    <article>
                      <p><strong>Recuerda</strong> que el trabajo doméstico también es una ocupación, así como la ocupación de cuidados. </p>
                    </article>
                  </div>
                </Alert>
                <SocioCulturalComponent />
              </div>
              <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
                <figure className="image-350">
                  <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
                </figure>
              </aside>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SocioCulturalScreen;