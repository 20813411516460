import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';


const IncidenciasComponent = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formularioValido, setFormularioValido] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);


  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
        console.log(campo)
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
          console.log(campoCompleto)
        }
      }
    });
  
    gruposRadio.forEach(nombre => {
      const seleccionado = document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;

      console.log(seleccionado)
    });
  
    setFormularioValido(todosCompletos);
  };
  
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxSocialChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };

  
  
  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };


  useEffect(() => {
    verifyFields();
  }, []);

  const handleSubmit = () => {
    navigate('/mi-proceso');
  };

  return (
    <>
     <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
          />
       <div className="form-block module-top40">
          <form onSubmit={handleSubmit}>

          <div className="form-row">
              <label className="form-label label--icon">
                ¿Sabías que existe una ley de aborto en Chile por tres Causales?  <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es muy importante para nosotras generar estadísticas generales sobre quiénes abortan y qué información tienen respecto al aborto.<br/> 

Estas estadísticas ayudan a despenalizar socialmente a quienes abortan. 

                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="leyAborto" id="leySi" value="si"/>
                  <label className="form-check-label" htmlFor="leySi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="leyAborto" id="leyNo" value="no"/>
                  <label className="form-check-label" htmlFor="leyNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="leyAborto" id="leyNoSeguro" value="no-estoy-segura"/>
                  <label className="form-check-label" htmlFor="leyNoSeguro">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>

            <div className="form-row">
              <Alert variant='success' className="alert--soft-success">
                <span className="icon material-icons-outlined">info</span>
                <article>
                  <p>Si te interesa saber más al respecto puedes hacerlo en el siguiente enlace: <a href="https://olachile.org/" target="_blank" rel="noreferrer">https://olachile.org/</a></p>
                </article>
                </Alert>
            </div>

            <div className="form-row">
              <label className="form-label label--icon">
                ¿Sabias que es seguro, rápido y eficaz abortar con medicamentos?  <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es muy importante para nosotras generar estadísticas generales sobre quiénes abortan y qué información tienen respecto al aborto. <br/>

Estas estadísticas ayudan a despenalizar socialmente a quienes abortan. 

                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="medicamentos" id="medicamentosSi" value="si"/>
                  <label className="form-check-label" htmlFor="medicamentosSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="medicamentos" id="medicamentosNo" value="no"/>
                  <label className="form-check-label" htmlFor="medicamentosNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="medicamentos" id="medicamentosNoSeguro" value="no-estoy-segura"/>
                  <label className="form-check-label" htmlFor="medicamentosNoSeguro">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Cómo te enteraste de la Red de Salud Comunitaria? <small className="required">*</small> <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Es muy importante para nosotras generar estadísticas generales sobre quiénes abortan y qué información tienen respecto al aborto. <br/>

Estas estadísticas ayudan a despenalizar socialmente a quienes abortan. 


                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <p className="text-gray600">Puedes seleccionar más de una opción</p>
              <div className="row-check-2 row">
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="amiga" value="Amiga"  onChange={handleCheckboxSocialChange}/>
                    <label className="form-check-label" htmlFor="amiga">
                    Amiga
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="instagram" value="Instagram"  onChange={handleCheckboxSocialChange}/>
                    <label className="form-check-label" htmlFor="instagram">
                    Instagram
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="facebook" value="Facebook"  onChange={handleCheckboxSocialChange}/>
                    <label className="form-check-label" htmlFor="facebook">
                    Facebook
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="tiktok" value="Tik Tok"  onChange={handleCheckboxSocialChange}/>
                    <label className="form-check-label" htmlFor="tiktok">
                    Tik Tok
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="stickers" value="Stickers"  onChange={handleCheckboxSocialChange}/>
                    <label className="form-check-label" htmlFor="stickers">
                    Stickers
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="profesionalSalud" value="Profesional de la salud"  onChange={handleCheckboxSocialChange}/>
                    <label className="form-check-label" htmlFor="profesionalSalud">
                    Profesional de la salud
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="otraRed" value="Otra Red"  onChange={handleCheckboxSocialChange}/>
                    <label className="form-check-label" htmlFor="otraRed">
                    Otra Red
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="google" value="Google"  onChange={handleCheckboxSocialChange}/>
                    <label className="form-check-label" htmlFor="google">
                    Google
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="familiar" value="Familiar"  onChange={handleCheckboxSocialChange}/>
                    <label className="form-check-label" htmlFor="familiar">
                    Familiar
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="otra" value="Otra"  onChange={handleCheckboxSocialChange}/>
                    <label className="form-check-label" htmlFor="otra">
                    Otra
                    </label>
                  </div>
                </div>
               
             
              </div>
             
            </div>

            <div className="form-row module-top40 form--last">
              <button
                type="submit"
                className="btn btn--type1"
                disabled={!formularioValido}
              >Guardar y continuar</button>
            </div>
            <div className="form-row form--last">
            <button
                type="button"
                className="btn--danger"
                onClick={() => setModalShow(true)}
              >Borrar mi registro</button>
            </div>
          </form>
        </div>
    </>
  );
}

export default IncidenciasComponent;