import React from 'react';
import HeaderShared from '../../shared/header.shared';
import FooterShared from '../../shared/footer.shared';
import BannerColorComponent from '../../components/Banners/Color/bannerColor.component';
import CardWrapperComponent from '../../components/Cards/cardWrapp.component';
import cards from '../../utils/cardsHome.json';


const HomeScreen: React.FC = () => {
 
  return (
    <>
      <HeaderShared />
      <section className="hero-intro module">
        <div className="container">
          <div className="row row-middle">
            <div className="hero-intro__col-top col-lg-7 col-12">
              <h1 className="text-52">Te acompañamos en el cumplimiento de la ley de aborto <span className="text-wine400">en Chile</span></h1>
              <div className="text-center mt-5">
                <a href="/crear-cuenta" className="btn btn--type1 btn--100 mb-4">Crear una cuenta</a>
                <a href="/iniciar-sesion" className="btn btn--simple">Iniciar sesión</a>
              </div>
            </div>
            <aside className="col-lg-5 col-12 d-flex row-end">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
          </div>
        </div>
      </section>
      <BannerColorComponent />
      <CardWrapperComponent title="Lo que hacemos en OLA" cards={cards} />
      <FooterShared />
    </>
  );
};

export default HomeScreen;