import React, {useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import StepperComponent from '../../../components/Stepper/stepper.component';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ViolentometroComponent from '../../../components/Auth/Violentometro/violentometro.component';

const ViolentometroScreen: React.FC = () => {
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [activeStep, setActiveStep] = useState(6); // Estado para el paso actual
  return (
    <>
    <HeaderShared />
    <section className="module container">
      <div className="body-row row">
        <div className="col-12">
          <div className="module-bottom row">
            <div className="col-12">
              <PrivacyComponent />
            </div> 
          </div>
          <div className="auth-row module-bottom row">
            <div className="module-bottom40 col-12">
            <StepperComponent activeStep={activeStep} />
            </div>
            <div className="col-lg-5 col-12">
              <h1>Violentómetro</h1>
              <article className="text-20 col-12 col-reset">
                <p className="text-green350">Quédate tranquila, tus datos siempre están protegidos.</p>
                <p>La violencia <strong>también se mide</strong>. Si te encuentras en algún nivel del <strong>violentómetro</strong> o simplemente no estás segura de sufrir violencia <strong>busca ayuda</strong> con tus autoridades locales, ellas te pueden canalizar y dar orientación según la situación en que te encuentres. Recuerda actuar antes de que sea <strong>demasiado tarde</strong>.</p>
                  <p>Selecciona los niveles del <strong>violentómetro</strong> con los que te sientas identificada.</p>
              </article>
              <ViolentometroComponent />
            </div>
            <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
          </div>
          
        </div>
      </div>
    </section>
    <FooterShared />
  </>
  );
};

export default ViolentometroScreen;