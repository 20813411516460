import React, {useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import StepperComponent from '../../../components/Stepper/stepper.component';
import SaludGinecologicaComponent from '../../../components/Auth/SaludGinecologica/saludGinecologica.component';

const SaludGinecologicaScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeStep, setActiveStep] = useState(5); // Estado para el paso actual
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div> 
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
              <StepperComponent activeStep={activeStep} />
              </div>
              <div className="col-lg-5 col-12">
                <h1>Salud Ginecológica</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-green350">Quédate tranquila, tus datos siempre están protegidos.</p>
                  <p>El objetivo de las próximas preguntas es recabar información esencial sobre <strong>tu salud ginecológica</strong> para proporcionarte un apoyo más integral durante este proceso.</p>
                  <p>Es importante que sepas que tienes <strong>derecho a un procedimiento seguro</strong>, basado en una información completa y sin prejuicios.</p>
                </article>
               
                <SaludGinecologicaComponent />
              </div>
              <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
                <figure className="image-350">
                  <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
                </figure>
              </aside>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SaludGinecologicaScreen;