import React, {useState} from 'react';
import { Alert } from 'react-bootstrap';

interface CalculadoraEmbarazoProps {
  onEmbarazoInfo: (semanas: number, dias: number, estaCompleto: boolean) => void;
}


const CalculadoraEmbarazo: React.FC<CalculadoraEmbarazoProps> = ({ onEmbarazoInfo }) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const [semanas, setSemanas] = useState(0);
  const [dias, setDias] = useState(0);

  const calculatePregnancyWeeks = () => {
    if (selectedYear && selectedMonth !== null && selectedDay) {
      // Crear una fecha a partir de los valores seleccionados
      const startDate = new Date(selectedYear, selectedMonth, selectedDay);
      const currentDate = new Date();
  
      // Calcular la diferencia en milisegundos
      const diffInMilliseconds = currentDate.getTime() - startDate.getTime();
  
      // Convertir milisegundos en días (1 día = 24 horas * 60 minutos * 60 segundos * 1000 milisegundos)
      const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
  
      // Calcular semanas y días
      const weeks = Math.floor(diffInDays / 7);
      const days = Math.floor(diffInDays % 7);
  
      // Actualizar el estado con las semanas y días calculados
      setSemanas(weeks);
      setDias(days);
      onEmbarazoInfo(weeks, days, true);
    }
  };
  

  // Función para generar opciones de año
  const generateYearOptions = () => {
    const currentYear = currentDate.getFullYear();
    const years = []; 
    for (let i = currentYear; i >= currentYear - 1; i--) {
      years.push(<option key={i} value={i}>{i}</option>);
    }
    return years;
  };

  // Función para generar opciones de mes
  const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  const generateMonthOptions = () => {
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
  
    // Calcula la fecha mínima (40 semanas atrás)
    const minStartDate = new Date();
    minStartDate.setDate(currentDate.getDate() - 40 * 7); // Resta 280 días (40 semanas)
  
    const months = [];
  
    if (selectedYear !== null) {
      // Año actual
      if (selectedYear === currentYear) {
        for (let i = 0; i <= currentMonth; i++) {
          if (new Date(selectedYear, i, 1) >= minStartDate) {
            months.push(<option key={i} value={i}>{monthNames[i]}</option>);
          }
        }
      }
      // Año anterior
      else if (selectedYear < currentYear) {
        for (let i = 0; i <= 11; i++) {
          const monthDate = new Date(selectedYear, i, 1);
          if (monthDate >= minStartDate && monthDate <= currentDate) {
            months.push(<option key={i} value={i}>{monthNames[i]}</option>);
          }
        }
      }
    }
  
    return months;
  };
  
  
  

  // Función para generar opciones de día
  const generateDayOptions = () => {
    const maxDay = (selectedYear === currentDate.getFullYear() && selectedMonth === currentDate.getMonth()) 
      ? currentDate.getDate() 
      : new Date(selectedYear!, selectedMonth! + 1, 0).getDate();
    const days = [];
    for (let i = 1; i <= maxDay; i++) {
      days.push(<option key={i} value={i}>{i}</option>);
    }
    return days;
  };

  const resetFields = () => {
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedDay(null);
    setSemanas(0);
    setDias(0);
    onEmbarazoInfo(0, 0, false);
  };

  return (
    <>
      <label className="form-label label--icon">
        <strong>Calculadora de embarazo</strong>
      </label>
      <p className="text-gray600">Para empezar, ingresa la <strong>fecha de inicio de tu última regla</strong> para calcular en que semana te encuentras. </p>
      {(semanas > 0 || dias > 0) && (
        <Alert variant={'info'}>
          <p>Tienes 
            {(semanas > 0)&& (
              <>
                {' '}<strong><span className="semanas">{semanas}</span> semanas</strong>
              </>)}
            {(semanas == 0 && dias > 0)&& (
              <>
                <strong> <span className="dias">{dias}</span> días</strong>
              </>)}
            {(semanas != 0 && dias > 0) && (
            <>
                {' '}con <strong> <span className="dias">{dias}</span> días</strong>
              </>
            )}
          .</p>
        </Alert>
      )}
     
      <input type="hidden" id="calculadoraSemanas" value={semanas} />
      <input type="hidden" id="calculadoraDias" value={dias} />
      <div className="row">
        <div className="col-4">
          <label className="form-label label--icon">
            Año
          </label>
          <select
            className="form-select"
            aria-label="Año de última regla"
            value={selectedYear ?? 'default'}
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          >
            <option value="default" disabled>Seleccionar...</option>
            {generateYearOptions()}
          </select>
        </div>
        <div className="col-4">
          <label className="form-label label--icon">
            Mes
          </label>
          <select
            className="form-select"
            aria-label="Mes de última regla"
            value={selectedMonth ?? 'default'}
            onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
            disabled={!selectedYear}
          >
            <option value="default" disabled>Seleccionar...</option>
            {generateMonthOptions()}
          </select>
        </div>
        <div className="col-4">
          <label className="form-label label--icon">
            Día
          </label>
          <select
            className="form-select"
            aria-label="Día de última regla"
            value={selectedDay ?? 'default'}
            onChange={(e) => setSelectedDay(parseInt(e.target.value))}
            disabled={selectedMonth === null} 
        >
            <option value="default" disabled>Seleccionar...</option>
            {generateDayOptions()}
        </select>

        </div>
        <div className="col-12 mt-4">
          <button type="button" className="btn btn--type1 btn--100" onClick={calculatePregnancyWeeks}>Calcular semanas</button>
        </div>
        <div className="col-12 mt-4">
          <button type="button" className="btn btn--type2 btn--100" onClick={resetFields}>Volver a calcular</button>
        </div>
      </div>
    </>
  );
};

export default CalculadoraEmbarazo;
