import React, {useState} from 'react';
import CausalCard1Component from './Causalcard/Causal1/causal1.component';
import CausalCard2Component from './Causalcard/Causal2/causal2.component';
import CausalCard3Component from './Causalcard/Causal3/causal3.component';
import CausalModalComponent from '../../Modals/CausalModal/causalModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';

const CausalesComponent: React.FC = () => {
  const [modalShowCausales, setModalShowCausales] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setModalShowCausales(true)
  }

  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };

  return (
    <div>
      <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
          />
      <CausalModalComponent
        show={modalShowCausales}
        onHide={() => setModalShowCausales(false)}
      />

      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <CausalCard1Component />
          <hr />
        </div>
        <div className="form-row">
          <CausalCard2Component />
          <hr />
        </div>
        <div className="form-row">
          <CausalCard3Component />
        </div>
        <div className="module-top40 form-row form--last">
          <button
            type="submit"
            className="btn btn--type1"
          >Ya seleccioné en qué causal o causales estoy</button>
        </div>
        <div className="form-row form--last">
          <a href="#"  className="btn btn--type2">No estoy en ninguna causal</a>
        </div>
        
        <div className="form-row form--last">
        <button
                type="button"
                className="btn--danger"
                onClick={() => setModalShow(true)}
              >Borrar mi registro</button>
        </div>
      </form>
    </div>
  );
};

export default CausalesComponent;
