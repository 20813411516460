import React, {useState, useEffect} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const EvaluacionHospitalLey21Component: React.FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formularioValido, setFormularioValido] = useState(false);

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    let todosCompletos = true;
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        // Verificar si al menos uno del grupo está seleccionado
        const grupoSeleccionado = !!document.querySelector(`input[name="${campo.name}"]:checked`);
        todosCompletos = todosCompletos && grupoSeleccionado;
      } else {
        const campoCompleto = campo.value.trim() !== '';
        todosCompletos = todosCompletos && campoCompleto;
      }
    });
  
    setFormularioValido(todosCompletos);
  }
  
    

  useEffect(() => {
    verifyFields();
  }, []);


  const handleSubmit = () => {
    navigate('/seguimiento');
  };

  return (
    <>
   
      <div className="row">
        <div className="col-md-5 col-12">
          <h2 className="text-green900 text-40 mb-3">Si fuí al hospital pero
no accedí a la ley 21.030</h2>
          <article className="text-20">
            <p>Nos interesa recoger los motivos de por qué las mujeres no acceden a los abortos enmarcados en la ley 21.030.</p>
            <p>Compartiendo nuestras historias, contribuimos a documentar cómo es el acceso a la <strong>Ley IVE N° 21.030</strong>.</p>
            <p>Juntas abrimos camino a la salud de las mujeres en Chile.</p>
         
            <p className="text-green350"><strong>Recuerda que este proceso, así como tus datos están protegidos.</strong></p>
            <p>Selecciona una o más opciones que sucedieron en el acceso a la <strong>Ley 21.030</strong>.</p>

          </article>
          <div className="form-block">
            <form onSubmit={handleSubmit}>
           
            <div className="form-row ">
             
              <p className="text-20">Elige las respuestas que correspondan a tu situación:</p>
              <div className="form-block row-check-2 row--check-top mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="interrupcion" value="No logré contacto con equipo de Interrupción Voluntaria del Embarazo"  onChange={verifyFields}/>
                  <label className="form-check-label label--icon" htmlFor="interrupcion">
                    No logré contacto con equipo de Interrupción Voluntaria del Embarazo<OverlayTrigger
                  placement='bottom' overlay={<Tooltip >No logré contacto con equipo de Interrupción Voluntaria del Embarazo</Tooltip>}><span className="material-icons-outlined icon">help_outline</span></OverlayTrigger>
                  </label>
                </div>
              </div>
              <div className="form-block row-check-2 row--check-top mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="salud" value="Me encontré con trabajadores de la salud que impusieron sus creencias religiosas, morales y prejuicios por sobre mi derecho a la salud"  onChange={verifyFields}/>
                  <label className="form-check-label label--icon" htmlFor="salud">
                    Me encontré con trabajadores de la salud que impusieron sus creencias religiosas, morales y prejuicios por sobre mi derecho a la salud <OverlayTrigger
                  placement='bottom' overlay={<Tooltip >Me encontré con trabajadores de la salud que impusieron sus creencias religiosas, morales y prejuicios por sobre mi derecho a la salud</Tooltip>}><span className="material-icons-outlined icon">help_outline</span></OverlayTrigger>
                  </label>
                </div>
              </div>
              <div className="form-block row-check-2 row--check-top mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="infoErronea" value="Me encontré con trabajadores de la salud que me entregaron información errónea, falsa y/o incompleta"  onChange={verifyFields}/>
                  <label className="form-check-label label--icon" htmlFor="infoErronea">
                    Me encontré con trabajadores de la salud que me entregaron información errónea, falsa y/o incompleta <OverlayTrigger
                  placement='bottom' overlay={<Tooltip >Me encontré con trabajadores de la salud que me entregaron información errónea, falsa y/o incompleta</Tooltip>}><span className="material-icons-outlined icon">help_outline</span></OverlayTrigger>
                  </label>
                </div>
              </div>
              <div className="form-block row-check-2 row--check-top mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="obstaculo" value="Hubo presencia de obstructores de derecho. Me encontré con trabajadores de la salud que obstaculizaron mi acceso a información, tratamientos  y consultas médicas."  onChange={verifyFields}/>
                  <label className="form-check-label label--icon" htmlFor="obstaculo">
                    Hubo presencia de obstructores de derecho. Me encontré con trabajadores de la salud que obstaculizaron mi acceso a información, tratamientos  y consultas médicas. <OverlayTrigger
                  placement='bottom' overlay={<Tooltip >Hubo presencia de obstructores de derecho. Me encontré con trabajadores de la salud que obstaculizaron mi acceso a información, tratamientos  y consultas médicas.</Tooltip>}><span className="material-icons-outlined icon">help_outline</span></OverlayTrigger>
                  </label>
                </div>
              </div>
              <div className="form-block row-check-2 row--check-top mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="negativaAborto" value="Hubo presencia de objetores de conciencia, personal médico que manifestó explícitamente su negativa a participar de mi procedimiento de aborto "  onChange={verifyFields}/>
                  <label className="form-check-label label--icon" htmlFor="negativaAborto">
                    Hubo presencia de objetores de conciencia, personal médico que manifestó explícitamente su negativa a participar de mi procedimiento de aborto  <OverlayTrigger
                  placement='bottom' overlay={<Tooltip >Hubo presencia de objetores de conciencia, personal médico que manifestó explícitamente su negativa a participar de mi procedimiento de aborto </Tooltip>}><span className="material-icons-outlined icon">help_outline</span></OverlayTrigger>
                  </label>
                </div>
              </div>
              <div className="form-block row-check-2 row--check-top mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="causal3" value="Mi embarazo excede los límites gestacionales que se mencionan en la ley 21.030 en causal 3"  onChange={verifyFields}/>
                  <label className="form-check-label label--icon" htmlFor="causal3">
                    Mi embarazo excede los límites gestacionales que se mencionan en la <strong>ley 21.030</strong> en <strong>causal 3</strong> <OverlayTrigger
                  placement='bottom' overlay={<Tooltip >Mi embarazo excede los límites gestacionales que se mencionan en la ley 21.030 en causal 3</Tooltip>}><span className="material-icons-outlined icon">help_outline</span></OverlayTrigger>
                  </label>
                </div>
              </div>
              <div className="form-block row-check-2 row--check-top mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="servicioLento" value="Las respuestas del servicio de salud fueron lentas, estuve varios días haciendo trámites sin conseguir nada"  onChange={verifyFields}/>
                  <label className="form-check-label label--icon" htmlFor="servicioLento">
                    Las respuestas del servicio de salud fueron lentas, estuve varios días haciendo trámites sin conseguir nada <OverlayTrigger
                  placement='bottom' overlay={<Tooltip >Las respuestas del servicio de salud fueron lentas, estuve varios días haciendo trámites sin conseguir nada</Tooltip>}><span className="material-icons-outlined icon">help_outline</span></OverlayTrigger>
                  </label>
                </div>
              </div>
              <div className="form-block row-check-2 row--check-top mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="comite" value="Hubo presencia de Comité de Ética que obstaculizó mi acceso a un aborto en el hospital"  onChange={verifyFields}/>
                  <label className="form-check-label label--icon" htmlFor="comite">
                    Hubo presencia de Comité de Ética que obstaculizó mi acceso a un aborto en el hospital <OverlayTrigger
                  placement='bottom' overlay={<Tooltip >Hubo presencia de Comité de Ética que obstaculizó mi acceso a un aborto en el hospital</Tooltip>}><span className="material-icons-outlined icon">help_outline</span></OverlayTrigger>
                  </label>
                </div>
              </div>
              <div className="form-block row-check-2 row--check-top mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="examen" value="Por medio del examen ecográfico se puso en duda mi relato "  onChange={verifyFields}/>
                  <label className="form-check-label label--icon" htmlFor="examen">
                    Por medio del examen ecográfico se puso en duda mi relato  <OverlayTrigger
                  placement='bottom' overlay={<Tooltip >Por medio del examen ecográfico se puso en duda mi relato </Tooltip>}><span className="material-icons-outlined icon">help_outline</span></OverlayTrigger>
                  </label>
                </div>
              </div>
              <div className="form-block row-check-2 row--check-top mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="carencia" value="No logré contar con la atención médica necesaria. Existe carencia de especialistas en el servicio de salud que me atendió."  onChange={verifyFields}/>
                  <label className="form-check-label label--icon" htmlFor="carencia">
                    No logré contar con la atención médica necesaria. Existe carencia de especialistas en el servicio de salud que me atendió. <OverlayTrigger
                  placement='bottom' overlay={<Tooltip >No logré contar con la atención médica necesaria. Existe carencia de especialistas en el servicio de salud que me atendió.</Tooltip>}><span className="material-icons-outlined icon">help_outline</span></OverlayTrigger>
                  </label>
                </div>
              </div>
              <div className="form-block row-check-2 row--check-top mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="ley21" value="Me exigieron una denuncia policial para tener acceso a la Ley 21.030  "  onChange={verifyFields}/>
                  <label className="form-check-label label--icon" htmlFor="ley21">
                    Me exigieron una denuncia policial para tener acceso a la <strong>Ley 21.030 </strong>  <OverlayTrigger
                  placement='bottom' overlay={<Tooltip >Me exigieron una denuncia policial para tener acceso a la Ley 21.030  </Tooltip>}><span className="material-icons-outlined icon">help_outline</span></OverlayTrigger>
                  </label>
                </div>
              </div>
             
              <div className="form-block row-check-2 row--check-top mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="causal1" value="El equipo médico evaluó que no estaba en riesgo vital, por lo tanto me negaron la Causal 1"  onChange={verifyFields}/>
                  <label className="form-check-label label--icon" htmlFor="causal1">
                    El equipo médico evaluó que no estaba en riesgo vital, por lo tanto me negaron la Causal 1 <OverlayTrigger
                  placement='bottom' overlay={<Tooltip >El equipo médico evaluó que no estaba en riesgo vital, por lo tanto me negaron la Causal 1</Tooltip>}><span className="material-icons-outlined icon">help_outline</span></OverlayTrigger>
                  </label>
                </div>
              </div>
             
           
            </div>
            <div className="form-row">
            <label className="form-label label--icon">
            Ingresa una razón adicional 
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                    Ingresa una razón adicional
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <textarea className="form-control" id="otherUrgencia" rows={3} placeholder='¿Alguna que quieras agregar?'  onChange={verifyFields}></textarea>
            </div>
              <div className="form-row  form--last">
                <button
                  type="submit"
                  className="btn btn--type1"
                  disabled={!formularioValido}
                >Siguiente</button>
              </div>
            </form>
          </div>
        </div>
        <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-end">
          <figure className="image-350">
            <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
          </figure>
        </aside>
      </div>
    </>
  );
};

export default EvaluacionHospitalLey21Component;
