import React, { useState } from 'react';
import VerificationInput from 'react-verification-input';
import { Link, useNavigate } from 'react-router-dom';


const CodePhoneComponent: React.FC = () => {
  const [code, setCode] = useState('');
  const isCodeComplete = code.length === 6; // Asumiendo que el código tiene 6 caracteres
  const navigate = useNavigate();



  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate('/datos-sociodemograficos');
  };



  return (
    <div className="form-block">
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <VerificationInput
            value={code}
            onChange={setCode}
            classNames={{
              container: 'container',
              character: 'character',
              characterInactive: 'character--inactive',
              characterSelected: 'character--selected',
            }}
            length={6}
          />
        </div>
        <div className="form-row form--last module-top40">
          <button
            type="submit"
            className="btn btn--type1"
            disabled={!isCodeComplete}
          >
            Siguiente
          </button>
        </div>
        <div className="form-row form--last">
          <button
            type="button"
            className="btn btn--type2"
          >
            Reenviar código
          </button>
        </div>
        <div className="form-row form--last">
          <Link to="/codigo-email" className="btn--simple">
            No tengo mi teléfono, enviar código a mi correo
          </Link>
        </div>
      </form>
    </div>
  );
};

export default CodePhoneComponent;
