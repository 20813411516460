/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { Alert } from 'react-bootstrap';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';

const PhoneComponent = () => {
  const navigate = useNavigate();
  const [showNameAlert, setShowNameAlert] = useState(false);
  const [showPhoneAlert, setShowPhoneAlert] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const [name, setName] = useState(''); // Declare and initialize the 'name' state variable
  const [phone, setPhone] = useState(''); // Declare and initialize the 'phone' state variable

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validForm) {
      navigate('/codigo-de-activacion');
    }
  };

  const handleDelete = () => {
    setModalShow(false);
    setModalShowConfirm(true);
  };

  useEffect(() => {
    // Check if the form fields are filled
    if (name && phone) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [name, phone]);

  return (
    <>
      <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label
              htmlFor="name"
              className={`form-label label--icon ${
                showNameAlert ? 'error' : ''
              }`}
            >
              Seudónimo / Nombre Apellido <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Es importante para nosotras poder identificarte como tu lo
                    desees. Por motivos de seguridad, puedes usar un seudónimo o
                    solo tu nombre.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="text"
              className={`form-control ${showNameAlert ? 'error' : ''}`}
              id="name"
              placeholder="¿Cómo te llamas?"
              onChange={(e) => setName(e.target.value)}
            />
            {showNameAlert && (
              <Alert variant="danger" className="alert--small mt-3">
                Es necesario ingresar tu Seudónimo / Nombre Apellido.
              </Alert>
            )}
          </div>
          <div className="form-row">
            <label
              className={`form-label label--icon ${
                showPhoneAlert ? 'error' : ''
              }`}
            >
              Tu número telefónico <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Esta información es importante para mantenernos en contacto
                    a través de llamadas y/o chats.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <PhoneInput
              defaultCountry="cl"
              placeholder="Enter phone number"
              className={`${showPhoneAlert ? 'error' : ''}`}
              onChange={(phone) => setPhone(phone)}
            />
            {showPhoneAlert && (
              <Alert variant="danger" className="alert--small mt-3">
                Ingresa un número teléfonico válido.
              </Alert>
            )}
          </div>
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!validForm} // Disable the button if the form is not valid
            >
              Guardar y continuar
            </button>
          </div>
          <div className="form-row form--last">
            <button
              type="button"
              className="btn--danger"
              onClick={() => setModalShow(true)}
            >
              Borrar mi registro
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PhoneComponent;
