import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ActiveCodeComponent: React.FC = () => {
  const navigate = useNavigate();
  const [activeMethod, setActiveMethod] = useState<'email' | 'phone' | null>(
    null
  );


  const handleMethodClick = (method: 'email' | 'phone') => {
    setActiveMethod(method);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (activeMethod === 'email') {
      navigate('/codigo-email');
    } else if (activeMethod === 'phone') {
      navigate('/codigo-telefonico');
    }
  };

 
  return (
    <>
     
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="active-choose">
              <button
                type="button"
                className={`btn btn--code ${
                  activeMethod === 'email' ? 'active' : ''
                }`}
                onClick={() => handleMethodClick('email')}
              >
                <i className="fa-regular fa-envelope"></i>
                <p className="text-24">Correo electrónico</p>
              </button>
              <button
                type="button"
                className={`btn btn--code ${
                  activeMethod === 'phone' ? 'active' : ''
                }`}
                onClick={() => handleMethodClick('phone')}
              >
                <i className="fa-brands fa-whatsapp"></i>
                <p className="text-24">Número telefónico</p>
              </button>
            </div>
          </div>
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!activeMethod}
            >
              Guardar y continuar
            </button>
          </div>
          
        </form>
      </div>
    </>
  );
};

export default ActiveCodeComponent;
