import React from 'react';

interface Props {
  // Define the props for your component here
}

const BannerColorComponent: React.FC<Props> = () => {
  // Implement your component logic here

  return (
    <div className="banner-color container">
      <div className="row row-middle">
        <div className="col-md-7 col-12">
          <h2 className="text-40 text-wine400 mb-4">¿Quiénes somos?</h2>
          <p className="text-24">Una organización de lesbianas feministas y acompañantes de aborto, que asumimos como nuestra responsabilidad política el seguimiento de la Ley de Interrupción Voluntaria del Embarazo y el acompañamiento en su acceso, haciendo acciones de exigibilidad al interior de los Servicios de Salud</p>
        </div>
        <aside className="col-md-5 col-12 d-flex justify-content-center">
          <figure className="image-270">
            <img src="/assets/images/illustration/justicia.png"  alt="Justicia"/>
          </figure>
        </aside>
      </div>
    </div>
  );
};

export default BannerColorComponent;