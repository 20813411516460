import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import CheckerBannerComponent from '../../Form/CheckerBanner/checkerBanner.component';

interface CausalModalProps {
  show: boolean;
  onHide: () => void;
}

const CausalModalComponent: React.FC<CausalModalProps> = (props) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [causalData, setCausalData] = useState({
    number: '',
    text: '',
    isChecked: false
  });

  const handleCausalChange = (number: string | undefined, text: string, isChecked: boolean) => {
    const actualNumber = number || ''; // Usar cadena vacía si number es undefined
    setCausalData({ number: actualNumber, text, isChecked });
  };
  

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          <h2>¿En qué consiste el acompañamiento de OLA?</h2>
          <p className="text-24 ">Somos Observadoras de la Ley de Aborto y te podemos acompañar 
en tu proceso de acceso a la ley 21.030 IVE (Interrupción Voluntaria del Embarazo)</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-card__row row">
          <div className="modal-card__col col-xl-4 col-12">
            <div className="card">
              <div className="card-body text-center">
                <h3 className="text-regular text-24 card-title">Tendrás acompañamiento diario</h3>
                <p className="card-text">Se te asignará una acompañante para darle el seguimiento adecuado antes, durante y después del procedimiento. Su misión será que seas atendida y respaldada siempre resguardando tus derechos. </p>
              </div>
              <a href="https://olachile.org/" target="_blank" rel="noreferrer" className="btn btn--type1 btn--p20">Quiero acceder a la Ley 21.030</a>
            </div>
          </div>
          <div className="modal-card__col col-xl-4 col-12">
            <div className="card">
              <div className="card-body text-center">
                <h3 className="text-regular text-24 card-title">Serás acompañada por una especialista en aborto por causales</h3>
                <p className="card-text">Podrás comunicarte por teléfono o chat con tu acompañante. Juntas van a evaluar cuál es la mejor manera de acceder a un aborto seguro por causales. Nuestro equipo está altamente capacitado en cada una de las enfermedades y diagnósticos contemplados en la Ley N° 21.030 y trabaja desde el año 2018 en coordinación directa con los equipos de salud del sector público, especialmente con las duplas del <a href="https://conlasamigas.org/wp-content/uploads/2024/02/NORMA-IVE-ACOMPANAMIENTO_02.pdf" target="_blank" rel="noreferrer" className="text-green500 ">programa de acompañamiento IVE</a>.  </p>
              </div>
              <a href="https://olachile.org/" target="_blank" rel='noreferrer' className="btn btn--type1 btn--icon btn--p20">
              <span className="material-icons-outlined icon">file_download</span> Descargar ruta de acceso a Ley 21.030</a>
            </div>
          </div>
          <div className="modal-card__col col-xl-4 col-12">
            <div className="card">
              <div className="card-body text-center">
                <h3 className="text-regular text-24 card-title">El derecho a abortar es ley en Chile</h3>
                <p className="card-text">Desde el año 2017 existe en Chile el aborto por 3 causales.</p>
                <p>Te invitamos a ser parte de la fuerza que empuja a que nuestros derechos se cumplan.</p>
                <div className="checkers">
                  <CheckerBannerComponent
                    onChange={handleCausalChange}
                    text={<span>Quiero acceder a un aborto por las tres Causales.</span>}
                    value="Quiero acceder a un aborto por las tres Causales"
                  />
                  <CheckerBannerComponent
                    onChange={handleCausalChange}
                    text={<span>Soy profesional de la salud.</span>}
                    value="Soy profesional de la salud"
                  />
                  <CheckerBannerComponent
                    onChange={handleCausalChange}
                    text={<span>Quiero ser Gestora Comunitaria.</span>}
                    value="Quiero ser Gestora Comunitaria"
                  />
                  <CheckerBannerComponent
                    onChange={handleCausalChange}
                    text={<span>Quiero donar.</span>}
                    value="Quiero donar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 text-center mb-4">
          <a href="/contexto-sociocultural"
            className="btn btn--type1"
          >Continuar mi registro con Las Amigas</a>
        </div>
       
        <div className="col-12 text-center mb-4">
          <button
            type="button"
            onClick={props.onHide}
            className="btn--danger text-purple400"
          >No estoy segura de querer continuar</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CausalModalComponent;
