import React, { useState, useCallback, useRef, useEffect } from 'react';
import Dropzone, { DropzoneRef, FileWithPath } from 'react-dropzone';

interface DropZoneComponentProps {
  onFilesChanged: (hasFiles: boolean) => void;
}

const DropZoneComponent = ({ onFilesChanged }: DropZoneComponentProps) => {
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const dropzoneRef = useRef<DropzoneRef>(null);

  const openDialog = () => {
    if (dropzoneRef.current && files.length < 3) {
      dropzoneRef.current.open();
    }
  };

  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    // Filtrar archivos que son mayores a 10 MB
    const filteredFiles = acceptedFiles.filter(file => file.size <= 10485760);
    setFiles(prevFiles => [...prevFiles, ...filteredFiles].slice(0, 3));
  }, []);

  const removeFile = (file: FileWithPath) => {
    setFiles(curr => curr.filter(f => f !== file));
  };

  useEffect(() => {
    onFilesChanged(files.length > 0);
  }, [files, onFilesChanged]);

  const accept = {
    'image/jpeg': ['.jpeg', '.jpg'],
    'application/pdf': ['.pdf']
  };

  return (
    <Dropzone 
      ref={dropzoneRef} 
      noClick 
      noKeyboard 
      onDrop={onDrop}
      accept={accept}
      maxSize={10485760} // 10 MB en bytes
    >
      {({ getRootProps, getInputProps }) => (
        <div className="container col-reset">
          <div {...getRootProps({ className: `dropzone ${files.length === 3 ? 'disabled' : ''}` })}>
            <input {...getInputProps()} />
            {files.length >= 1 ? <i className="icon icon--upload-checked"></i> : <i className="icon icon--upload"></i>}
            
            <p className="text-purple800 text-500">Arrastra tu archivo aquí</p>
            <button type="button" className="btn btn--type1" onClick={openDialog} disabled={files.length === 3}>
              {files.length >= 1 ? 'Ingresa un archivo adicional' : 'Seleccionar un archivo'}
            </button>
          </div>
          {files.length > 0 && (
            <aside className="dropzone__files alert alert-light">
              <p><strong>Archivos a subir</strong></p>
              <ul>
                {files.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <button onClick={() => removeFile(file)}>Quitar</button>
                  </li>
                ))}
              </ul>
            </aside>
          )}
        </div>
      )}
    </Dropzone>
  );
};

export default DropZoneComponent;
