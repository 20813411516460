import React from 'react';
import RatingComponent from '../Rating/rating.component';

interface Props {
  name?: string;
  region?: string;
  
}

const EvaluationCardComponent: React.FC<Props> = ({name, region}) => {
  // Implement your component logic here

  return (
    <div className="rating-card">
      <div className="col-12 mb-3">
        {name ? <h3 className="text-24">{name}</h3> : null}
        {region ? <p className="text-gray600">{region}</p> : null}
      </div>
      <p><strong>Calificación</strong></p>
      
      <RatingComponent />
    </div>
  );
};

export default EvaluationCardComponent;