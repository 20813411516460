import React, {useState} from 'react';
import StopProcessComponent from '../../Modals/StopProcessModal/stopProcessModal.component';
import StopConfirmComponent from '../../Modals/StopConfirmModal/stopConfirmModal.component';

interface Props {
  accepted?: boolean;
}


const Step1Component: React.FC<Props> = ({accepted}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalShow, setModalShow] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };
  return (
    <>
     <StopProcessComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <StopConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
        />
      <div className="col-12">
        {accepted ? (
          <h3 className="h2 text-400">¿Qué quiere decir esto?</h3>
          
        ) : (
          <h3 className="h2 text-400">Estamos asignándote gestora</h3>
        )}
        <div className="alert alert--simple-check col-md-8 col-12">
          <span className="icon material-icons-outlined">check_circle</span>
          <span className="text-20"><strong>Recibimos tu información completa. Estamos asignándote a tu acompañante. Te mantendremos informada.</strong></span>
        </div>
        <article>
          <ul className="text-20">
            <li className="mb-3">Antes, durante y después del aborto, una acompañante de OLA, - especializada en causales - estará contigo y podrás contactarla a diario para orientarte y juntas resolver dudas que tengas sobre este proceso.</li>
          </ul>
        </article>
      </div>
      <div className="col-lg-5 col-12">
        <div className="form-row module-top40 form--last">
          {accepted ? (
             <a
             href="/acompanamiento"
             className="btn btn--type3"
           >Ver acompañante asignada</a>
          ) : (
            <a
            href="/acompanamiento"
            className="btn btn--type1"
          >Ver acompañamiento</a>
          )}
        
        </div>
        {!accepted ? <div className="form-row form--last">
          <button
            type="button"
            className="btn btn--type2"
            onClick={() => setModalShow(true)}
          >Ya no necesito este acompañamiento</button>
        </div> : null}
        
      </div>
    </>
  );
};

export default Step1Component;
