import React, {useState, useEffect} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import metodoData from '../../../utils/metodos-anticonceptivos.json'; 
import embarazosData from '../../../utils/embarazos.json'; 
import tiempoEmbarazo from '../../../utils/semanas-dias.json'; 
import { useNavigate } from 'react-router-dom';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';
import DropZoneComponent from '../../Form/DropZone/dropZone.component';
import CausalCard1Component from '../Causales/Causalcard/Causal1/causal1.component';
import CausalCard2Component from '../Causales/Causalcard/Causal2/causal2.component';
import CalculadoraEmbarazo from '../../Form/CalculadoraEmbarazo/calculadoraEmbarazo.component';

const SaludGinecologicaComponent = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [embarazos, setEmbarazos] = useState(embarazosData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cesareas, setCesareas] = useState(embarazosData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [vaginales, setVaginales] = useState(embarazosData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [abortos, setAbortos] = useState(embarazosData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [metodoAnticonceptivo, setMetodoAnticonceptivo] = useState(metodoData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [semanasEmbarazo, setSemanasEmbarazo] = useState(tiempoEmbarazo.semanasEmbarazo);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [diasEmbarazo, setDiasEmbarazo] = useState(tiempoEmbarazo.diasPostEmbarazo);
  const [formularioValido, setFormularioValido] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [embarazoInfo, setEmbarazoInfo] = useState({ semanas: 0, dias: 0, estaCompleto: false });
  const [mostrarCalculadora, setMostrarCalculadora] = useState(true);

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
          console.log(campoCompleto)
        }
      }
    });
  
    gruposRadio.forEach(nombre => {
      const seleccionado = document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;

      console.log(seleccionado)
    });
  
    setFormularioValido(todosCompletos);
  };
  
  const handleUrgenciaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMostrarCalculadora(e.target.value === "no");
  };

  const handleEmbarazoInfo = (semanas: number, dias: number, estaCompleto: boolean) => {
    setEmbarazoInfo({ semanas, dias, estaCompleto });
  };
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxAbortoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxUrgenciaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxComprobacionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };
  

  const handleFilesChanged = (hasFiles: boolean) => {
    console.log('¿Hay archivos?:', hasFiles);
  };
  const handleFilesAdditionalChanged = (hasFiles: boolean) => {
    console.log('¿Hay archivos?:', hasFiles);
  };

  const handleAbortosChange = () => {
    verifyFields();
  };
  
  const handleAnticonceptivoChange = () => {
    verifyFields();
  };
  const handleSemanasChange = () => {
    verifyFields();
  };
  const handleDiasChange = () => {
    verifyFields();
  };
  
  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };


  useEffect(() => {
    verifyFields();
  }, [embarazos, abortos]);

  const handleSubmit = () => {
    navigate('/salud-y-emociones');
  };

  return (
    <>
     <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
          />
       <div className="form-block">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label className="form-label label--icon">
              Contando éste embarazo, ¿Cuántas veces has estado embarazada? <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es muy importante para nosotras tener información general sobre tu historial ginecológico de manera de conocerte mejor y poder brindarte un acompañamiento más adecuado. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select requiredField"
                aria-label="¿Cuántas veces has estado embarazada?"
                defaultValue={'default'}
                name="embarazos"
                onChange={() => {
                  verifyFields();
                }}
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {embarazos.map((embarazo) => (
                  <option key={embarazo.id} value={embarazo.id}>{embarazo.label}</option>
                ))}
              </select>
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
                Número de partos por cesárea
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es muy importante para nosotras tener información general sobre tu historial ginecológico de manera de conocerte mejor y poder brindarte un acompañamiento más adecuado. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Número de partos por cesárea"
                defaultValue={'default'}
                name="cesaeas"
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {cesareas.map((cesareas) => (
                  <option key={cesareas.id} value={cesareas.id}>{cesareas.label}</option>
                ))}
              </select>
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
              Número de partos vaginales
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es muy importante para nosotras tener información general sobre tu historial ginecológico de manera de conocerte mejor y poder brindarte un acompañamiento más adecuado. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Número de partos vaginales"
                defaultValue={'default'}
                name="vaginales"
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {vaginales.map((vaginal) => (
                  <option key={vaginal.id} value={vaginal.id}>{vaginal.label}</option>
                ))}
              </select>
            </div>

            <div className="form-row">
              <label className="form-label label--icon">
              Número de abortos (inducidos, por causales o espontáneos) <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Es muy importante para nosotras tener información general sobre tu historial ginecológico de manera de conocerte mejor y poder brindarte un acompañamiento más adecuado. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select requiredField"
                aria-label=" Número de abortos"
                defaultValue={'default'}
                name="abortos"
                onChange={handleAbortosChange}
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {abortos.map((aborto) => (
                  <option key={aborto.id} value={aborto.id}>{aborto.label}</option>
                ))}
              </select>
            </div>

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Intentaste abortar antes en este embarazo? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Es muy importante tener esta información para ajustar protocolos de acción en caso de ser necesario.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="abortarHistorial" id="abortoSi" value="si"  onChange={handleCheckboxAbortoChange}/>
                  <label className="form-check-label" htmlFor="abortoSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="abortarHistorial" id="abortoNo" value="no"  onChange={handleCheckboxAbortoChange}/>
                  <label className="form-check-label" htmlFor="abortoNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="abortarHistorial" id="abortoNoSeguro" value="no-estoy-segura"  onChange={handleCheckboxAbortoChange}/>
                  <label className="form-check-label" htmlFor="abortoNoSeguro">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Qué previsión de salud tienes? <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     En el caso de requerir atención de salud (especialmente, quienes buscan acceder a un aborto gratuito por 3 causales en los Servicios de Salud Públicos), es importante que las mujeres sepamos cuál es el centro de salud que nos corresponde según nuestra previsión o convenio.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select requiredField"
                aria-label="¿Qué previsión de salud tienes?"
                defaultValue={'default'}
                name="metodoAnticonceptivo"
                onChange={handleAnticonceptivoChange}
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {metodoAnticonceptivo.map((metodo) => (
                  <option key={metodo.id} value={metodo.id}>{metodo.label}</option>
                ))}
              </select>
            </div>
                
            <div className="form-row">
              <label className="form-label label--icon">
                Si no está la opción, menciónala aquí
              </label>
              <textarea className="form-control" id="other" rows={3}></textarea>
            </div>

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Sabes a qué urgencia ginecológica o servicio de salud debes ir cuando lo necesitas? 
              <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Es muy importante que tengas clara la dirección y cómo llegar ahí en caso de ser necesario. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="urgencia" id="urgenciaSi" value="si"  onChange={handleCheckboxUrgenciaChange}/>
                  <label className="form-check-label" htmlFor="urgenciaSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="urgencia" id="urgenciaNo" value="no"  onChange={handleCheckboxUrgenciaChange}/>
                  <label className="form-check-label" htmlFor="urgenciaNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="urgencia" id="urgenciaNoSeguro" value="no-estoy-segura"  onChange={handleCheckboxUrgenciaChange}/>
                  <label className="form-check-label" htmlFor="urgenciaNoSeguro">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>
            

            <div className="form-row">
              <label className="form-label label--icon">
                Escribe cuál
              </label>
              <textarea className="form-control" id="otherUrgencia" rows={3}></textarea>
            </div>


            <div className="form-row">
              <label className="form-label label--icon">
                ¿Sabes cuantas semanas de embarazo tienes?

              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="urgencia" id="urgenciaSi" value="si"  checked={!mostrarCalculadora}
          onChange={handleUrgenciaChange} />
                  <label className="form-check-label" htmlFor="urgenciaSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="urgencia" id="urgenciaNo" value="no" checked={mostrarCalculadora}
          onChange={handleUrgenciaChange}/>
                  <label className="form-check-label" htmlFor="urgenciaNo">
                    No
                  </label>
                </div>
              </div>
            </div>

            {!mostrarCalculadora && (

              <div className="form-row">
                <label className="form-label label--icon">
                  ¿Cuantas semanas calculas que tienes?
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip >
                        Conocer el tiempo de gestación es fundamental para tener un aborto seguro y ajustar protocolos.
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">help_outline</span>
                  </OverlayTrigger>
                </label>
                <p className="text-gray600">Si tienes un ecografía, guíate de las semanas que te indica el reporte</p>
                
                <div className="row">
                  <div className="col-6">
                        <label className="form-label label--icon">
                        Semanas
                        
                      </label>
                      <select
                      className="form-select "
                      aria-label="Semanas de embarazo"
                      defaultValue={'default'}
                      name="semanasEmbarazo"
                      onChange={handleSemanasChange}
                    >
                      <option value="default" disabled>Elige una de las opciones</option>
                      {semanasEmbarazo.map((semanas) => (
                        <option key={semanas.id} value={semanas.id}>{semanas.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-6">
                        <label className="form-label label--icon">
                        Días
                        
                      </label>
                      <select
                      className="form-select "
                      aria-label="Días de embarazo"
                      defaultValue={'default'}
                      name="diasEmbarazo"
                      onChange={handleDiasChange}
                    >
                      <option value="default" disabled>Elige una de las opciones</option>
                      {diasEmbarazo.map((dias) => (
                        <option key={dias.id} value={dias.id}>{dias.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}

          {mostrarCalculadora && (
            <div className="form-row">
              <CalculadoraEmbarazo onEmbarazoInfo={handleEmbarazoInfo}/>
            </div>
          )}

            <div className="form-row">
              <h3 className="h4 text-regular">Ecografía y Documentos</h3>
              <p><strong>(sólo si cuentas con ella)</strong></p>
              <label className="form-label label--icon">Si necesitas una ecografía puedes acudir a <strong className="text-green400">Aló-Eloísa</strong>  <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Aló Eloísa es un centro médico especializado en la atención de salud integral a mujeres y niñas, con servicios de obstetricia, psicología y medicina general.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger></label>
              <p className="text-14 text-gray800">Para que el proceso se lleve a cabo de la mejor manera, es muy importante tener claras las semanas de gestación y otra información relevante. </p>
              <p className="text-14 text-gray800">La ecografía es el método de comprobación que nos permite saber con exactitud cuantas semanas está cursando y otro tipo de diagnósticos clínicos.</p>
              <a href="https://aloeloisa.cl" target='_blank' rel="noreferrer" className="btn btn--type2">Ver listado de Centros de Salud Amigables</a>
            </div>

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Qué método utilizaste para comprobar tu embarazo? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es muy importante conocer el tiempo de gestación y las características del embarazo para ofrecer un acompañamiento adecuado a cada situación. <br/>La ecografía es el método más exacto para determinar el tiempo de gestación y las características del embarazo. 

                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <p className="text-gray800">Puedes seleccionar más de una opción</p>
              <div className="row-check-2 row">
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="fur" value="FUR (Fecha de Última Regla)"  onChange={handleCheckboxComprobacionChange}/>
                    <label className="form-check-label" htmlFor="fur">
                    FUR (Fecha de Última Regla)
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="ecografia" value="Ecografía"  onChange={handleCheckboxComprobacionChange}/>
                    <label className="form-check-label" htmlFor="ecografia">
                    Ecografía
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="test" value="Test o prueba (orina o sangre)"  onChange={handleCheckboxComprobacionChange}/>
                    <label className="form-check-label" htmlFor="test">
                    Test o prueba (orina o sangre)
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="no" value="No lo he hecho"  onChange={handleCheckboxComprobacionChange}/>
                    <label className="form-check-label" htmlFor="no">
                    No lo he hecho
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="otro" value="Otro"  onChange={handleCheckboxComprobacionChange}/>
                    <label className="form-check-label" htmlFor="otro">
                    Otro
                    </label>
                  </div>
                </div>
              </div>
             
            </div>

            <div className="form-row">
              <label className="form-label label--icon">
              Informe de Ecografía <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Las acompañantes pueden revisar junto con las mujeres sus informes ecográficos. Si lo deseas, puedes compartirlo por aquí. <br />

Por seguridad y cuidado, este documento será eliminado una vez revisado. Tu misma puedes eliminarlo en cualquier momento. <br />

y niñas, con servicios de obstetricia, psicología y medicina general.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <p className="icon-text text-gray700">
                <i className="icon icon--add-document"></i>
                Selecciona una imagen JPG o PDF (peso menor a 10 MB), puedes subir hasta 3 documentos
              </p>
              <DropZoneComponent onFilesChanged={handleFilesChanged} />
              <p className="mt-2">Por tu seguridad, estos datos serán destruidos en 60 días corridos</p>
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
              Archivos adicionales <small className="required">*</small>
              </label>
              <p className="icon-text text-gray700">
                <i className="icon icon--add-document"></i>
                Selecciona una imagen JPG o PDF (peso menor a 10 MB), puedes subir hasta 3 documentos
              </p>
              <DropZoneComponent onFilesChanged={handleFilesAdditionalChanged} />
              <p className="mt-2">Por tu seguridad, estos datos serán destruidos en 60 días corridos</p>
            </div>
            <div className="form-row">
              <p className="form-label label--icon">Revisa tu informe ecográfico y ve si coincide con alguno de los diagnósticos y patologías señaladas en la ley 21.030, causal 1 y causal 2:</p>
            </div>
            
            <div className="form-row">
              <CausalCard1Component />
            </div>
            <div className="form-row">
              <CausalCard2Component />
            </div>
           
            <div className="form-row module-top40">
              <p className="text-center">¡Y recuerda! Si lo necesitas podemos acordar una llamada</p>
              <a href="tel:569982729131" className="btn btn--type2 btn--icon">
                <i className="icon icon--phone-call"></i>
                Información de aborto legal en Chile 569 982 729 131
              </a>
            </div>
            <div className="form-row  form--last">
              <button
                type="submit"
                className="btn btn--type1"
                disabled={!formularioValido}
              >Guardar y continuar</button>
            </div>
            <div className="form-row form--last">
            <button
                type="button"
                className="btn--danger"
                onClick={() => setModalShow(true)}
              >Borrar mi registro</button>
            </div>
          </form>
        </div>
    </>
  );
}

export default SaludGinecologicaComponent;