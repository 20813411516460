import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';

type RatingProps = {
  maxRating?: number;
  onRating?: (rating: number) => void;
};

const RatingComponent: React.FC<RatingProps> = ({ maxRating = 5, onRating }) => {
  const [rating, setRating] = useState(0);

  const handleRating = (rate: number) => {
    setRating(rate);
    if (onRating) {
      onRating(rate);
    }
  };

  return (
    <div className="rating-form">
      {[...Array(maxRating)].map((_, index) => {
        const ratingValue = index + 1;
        return (
          <FontAwesomeIcon
          key={ratingValue}
          icon={ratingValue <= rating ? fasStar : farStar}
          onClick={() => handleRating(ratingValue)}
          style={{ cursor: 'pointer', color:  ratingValue <= rating ? '#F5C451' : '#CDCDCD'}} // Puedes cambiar el color según tu diseño
        />
          
        );
      })}
    </div>
  );
};

export default RatingComponent;
