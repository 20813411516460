import React, {useState} from 'react';
import hospitalesData from '../../utils/hospitales.json';
import HospitalCardMap from './hospitalMap.component';


interface Props {
  // Define the props for your component here
}

interface Hospital {
  id: number;
  name: string;
  lat: number;
  long: number;
  address: string;
  service: string;
  phone: string[];
  review: string;
}


const HospitalGridComponent: React.FC<Props> = () => {
  const hospitales: Hospital[] = hospitalesData.hospitales as Hospital[];
  const initialResultsToShow = 3;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hospitalesFiltrados, setHospitalesFiltrados] = useState<Hospital[]>(hospitales.slice(0, initialResultsToShow));

  return (
    <section className="hospital-wrapp">
      <div className="row">
          {hospitalesFiltrados.map((hospital, index) => ( // Mostrar los hospitales filtrados
            <HospitalCardMap
              key={index}
              id={hospital.id}
              name={hospital.name}
              service={hospital.service}
              address={hospital.address}
              phone={[hospital.phone[0]]}
              review={hospital.review}
              lat={hospital.lat}
              long={hospital.long}
            />
          ))}
        
        </div>
    </section>
  );
};

export default HospitalGridComponent;