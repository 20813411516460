import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';


const ViolentometroComponent = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formularioValido, setFormularioValido] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);


  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
        console.log(campo)
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
          console.log(campoCompleto)
        }
      }
    });
  
    gruposRadio.forEach(nombre => {
      const seleccionado = document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;

      console.log(seleccionado)
    });
  
    setFormularioValido(todosCompletos);
  };
  
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxViolentometroChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };

  
  
  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };


  useEffect(() => {
    verifyFields();
  }, []);

  const handleSubmit = () => {
    navigate('/incidencias');
  };

  return (
    <>
     <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
          />
       <div className="form-block module-top40">
          <form onSubmit={handleSubmit}>

            <section className="violent-section">
              <aside className="violent-figure">
                <div className="violent-level level1">
                  <div className="violent-figure__item item--color1"></div>
                  <div className="violent-figure__item item--color1"></div>
                  <div className="violent-figure__item item--color1"></div>
                  <div className="violent-figure__item item--color1"></div>
                  <div className="violent-figure__item item--color1"></div>
                  <p className="text-black"><strong>Ten cuidado, la violencia aumentará</strong></p>
                </div>
                <div className="violent-level">
                  <div className="violent-figure__item item--color2"></div>
                  <div className="violent-figure__item item--color2"></div>
                  <div className="violent-figure__item item--color2"></div>
                  <div className="violent-figure__item item--color2"></div>
                  <div className="violent-figure__item item--color2"></div>
                </div>
                <div className="violent-level">
                  <div className="violent-figure__item item--color3"></div>
                  <div className="violent-figure__item item--color3"></div>
                  <div className="violent-figure__item item--color3"></div>
                  <div className="violent-figure__item item--color3"></div>
                  <div className="violent-figure__item item--color3"></div>
                  <div className="violent-figure__item item--color3"></div>
                  <div className="violent-figure__item item--color3"></div>
                  <div className="violent-figure__item item--color3"></div>
                  <div className="violent-figure__item item--color3"></div>
                  <div className="violent-figure__item item--color3"></div>
                  <p className="text-black"><strong>No te dejes destruir, reacciona</strong></p>
                </div>
                <div className="violent-level">
                  <div className="violent-figure__item item--color4"></div>
                  <div className="violent-figure__item item--color4"></div>
                  <div className="violent-figure__item item--color4"></div>
                  <div className="violent-figure__item item--color4"></div>
                  <div className="violent-figure__item item--color4"></div>
                  <div className="violent-figure__item item--color4"></div>
                  <div className="violent-figure__item item--color4"></div>
                  <div className="violent-figure__item item--color4"></div>
                  <div className="violent-figure__item item--color4"></div>
                  <div className="violent-figure__item item--color4"></div>
                  <p className="text-black"><strong>Busca ayuda profesional</strong></p>
                </div>
               
                <div className="violent-figure__end"></div>
              </aside>
              <div className="violent-content">
                  <div className="violent-row row--center">
                  <p className="violent-number">0</p>
                  <div className="form-check check--center">
                    <input className="form-check-input requiredField" type="checkbox" value="Bromas hirientes" name="violentometro" id="noViolencia" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="bromasHirientes">
                      <strong>No sufro de violencia</strong>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">1</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Bromas hirientes" name="violentometro" id="bromasHirientes" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="bromasHirientes">
                      <strong>Bromas hirientes</strong>
                      <small>&quot;Que fea te ves hoy, es broma eh&quot;</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">2</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Chantajear" name="violentometro" id="chantajear" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="chantajear">
                      <strong>Chantajear</strong>
                      <small>&quot;Si no salimos hoy, no quiero que me hables&quot;</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row row--center">
                  <p className="violent-number">3</p>
                  <div className="form-check check--center">
                    <input className="form-check-input requiredField" type="checkbox" value="Mentir, engañar" name="violentometro" id="mentir" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="mentir">
                      <strong>Mentir, engañar</strong>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">4</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Ignorar, ley del hielo" name="violentometro" id="ignorar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="ignorar">
                      <strong>Ignorar, ley del hielo</strong>
                      <small>&quot;(Fingir que no te escucha)&quot;</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">5</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Celar" name="violentometro" id="celar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="celar">
                      <strong>Celar</strong>
                      <small>&quot;¿Por qué estabas hablando con él?&quot;</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">6</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Acechar, stalkear redes sociales" name="violentometro" id="acechar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="acechar">
                      <strong>Acechar, stalkear redes sociales </strong>
                      <small>&quot;¿Por qué le diste like a su foto?&quot; &quot;¿Por qué lo sigues?&quot;</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">7</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Culpabilizar " name="violentometro" id="culpabilizar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="culpabilizar">
                      <strong>Culpabilizar </strong>
                      <small>&quot;No puedo creer que desconfíes de mi&quot;</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">8</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Descalificar " name="violentometro" id="descalificar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="descalificar">
                      <strong>Descalificar </strong>
                      <small>&quot;Tú ¿qué vas a saber?&quot; &quot;mejor ni opines&quot;</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">9</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Ridiculizar, ofender" name="violentometro" id="ridiculizar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="ridiculizar">
                      <strong>Ridiculizar, ofender  </strong>
                      <small>&quot;Dices puras estupideces&quot;</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">10</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Humillar en público" name="violentometro" id="humillar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="humillar">
                      <strong>Humillar en público</strong>
                      <small>&quot;Perdónenla, no sabe de lo que habla&quot;</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">11</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Intimidar, amenazar" name="violentometro" id="intimidar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="intimidar">
                      <strong>Intimidar, amenazar</strong>
                      <small>(Tocarte de forma sexual sin tu permiso)</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">12</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Controlar, prohibir" name="violentometro" id="prohibir" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="prohibir">
                      <strong>Controlar, prohibir</strong>
                      <small>(Arrojar objetos, golpear y patear paredes, puertas, etc)</small>
                    </label>
                  </div>
                </div>
              
                <div className="violent-row">
                  <p className="violent-number">13</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Destruir artículos personales" name="violentometro" id="destruir" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="destruir">
                      <strong>Destruir artículos personales</strong>
                      <small>(Tocarte de forma sexual sin tu permiso)</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">14</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Manosear" name="violentometro" id="manosear" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="manosear">
                      <strong>Manosear</strong>
                      <small>(Tocarte de forma sexual sin tu permiso)</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">15</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Caricias agresivas" name="violentometro" id="caricias" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="caricias">
                      <strong>Caricias agresivas</strong>
                      <small>(Tocarte de forma sexual bruscamente y/o causando daño sin tu permiso)</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">16</p>
                  <div className="form-check check--center">
                    <input className="form-check-input requiredField" type="checkbox" value="Golpear “jugando“" name="violentometro" id="golpear" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="golpear">
                      <strong>Golpear &quot;jugando&quot;</strong>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">17</p>
                  <div className="form-check check--center">
                    <input className="form-check-input requiredField" type="checkbox" value="Pellizcar, arañar" name="violentometro" id="aranar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="aranar">
                      <strong>Pellizcar, arañar</strong>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">18</p>
                  <div className="form-check ">
                    <input className="form-check-input requiredField" type="checkbox" value="Empujar, jalonear" name="violentometro" id="empujar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="empujar">
                      <strong>Empujar, jalonear</strong>
                      <small>(Del cuerpo o de la ropa)</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">19</p>
                  <div className="form-check check--center">
                    <input className="form-check-input requiredField" type="checkbox" value="Escupir" name="violentometro" id="escupir" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="escupir">
                      <strong>Escupir</strong>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">20</p>
                  <div className="form-check check--center">
                    <input className="form-check-input requiredField" type="checkbox" value="Cachetear" name="violentometro" id="cachetear" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="cachetear">
                      <strong>Cachetear</strong>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">21</p>
                  <div className="form-check check--center">
                    <input className="form-check-input requiredField" type="checkbox" value="Patear" name="violentometro" id="patear" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="patear">
                      <strong>Patear</strong>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">22</p>
                  <div className="form-check ">
                    <input className="form-check-input requiredField" type="checkbox" value="Encerrar, aislar" name="violentometro" id="encerrar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="encerrar">
                      <strong>Encerrar, aislar</strong>
                      <small>(Alejarte de familiares, amigos y conocidos e incluso encerrarte)</small>
                    </label>
                  </div>
                </div>
                
                <div className="violent-row">
                  <p className="violent-number">23</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Amenazar con objetos o armas" name="violentometro" id="amenazar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="amenazar">
                      <strong>Amenazar con objetos o armas</strong>
                      <small>(Guardar armas en la casa para asustar)</small>
                    </label>
                  </div>
                </div>

                <div className="violent-row">
                  <p className="violent-number">24</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Difundir contenido íntimo" name="violentometro" id="difundir" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="difundir">
                      <strong>Difundir contenido íntimo</strong>
                      <small>(Enviar y/o subir fotos y/o videos íntimos, sexuales o personales)</small>
                    </label>
                  </div>
                </div>

                <div className="violent-row">
                  <p className="violent-number">25</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Amenazar de muerte" name="violentometro" id="amenazarMuerte" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="amenazarMuerte">
                      <strong>Amenazar de muerte</strong>
                      <small>&quot;Si le vuelves a hablar te mato&quot;</small>
                    </label>
                  </div>
                </div>
                
                <div className="violent-row">
                  <p className="violent-number">26</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Forzar a una relación sexual" name="violentometro" id="forzarRelacion" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="forzarRelacion">
                      <strong>Forzar a una relación sexual</strong>
                      <small>(Obligarte a sostener encuentros sexuales)</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">27</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Abuso sexual" name="violentometro" id="abusoSexual" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="abusoSexual">
                      <strong>Abuso sexual</strong>
                      <small>(Toda actividad sexual realizada en contra de tu voluntad)</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">28</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Violar" name="violentometro" id="violar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="violar">
                      <strong>Violar</strong>
                      <small>(Toda penetración por ano, boca o vagina usando la fuerza o intimidación)</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">29</p>
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" value="Mutilar" name="violentometro" id="mutilar" onChange={handleCheckboxViolentometroChange}/>
                    <label className="form-check-label" htmlFor="mutilar">
                      <strong>Mutilar</strong>
                      <small>(Cortar alguna parte de tu cuerpo)</small>
                    </label>
                  </div>
                </div>
                <div className="violent-row">
                  <p className="violent-number">30</p>
                  <div className="form-check pl-0">
                    <p className="form-check-label text-20">
                      <strong>Asesinar</strong>
                    </p>
                  </div>
                </div>
              </div>
            </section>
            
            <div className="form-row module-top40 form--last">
              <button
                type="submit"
                className="btn btn--type1"
                disabled={!formularioValido}
              >Guardar y continuar</button>
            </div>
            <div className="form-row form--last">
            <button
                type="button"
                className="btn--danger"
                onClick={() => setModalShow(true)}
              >Borrar mi registro</button>
            </div>
          </form>
        </div>
    </>
  );
}

export default ViolentometroComponent;