import React, {useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import StepperComponent from '../../../components/Stepper/stepper.component';
import CausalesComponent from '../../../components/Auth/Causales/causales.component';

const CausalesScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeStep, setActiveStep] = useState(2); // Estado para el paso actual
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
              <StepperComponent activeStep={activeStep} />
              </div>
              <div className="col-lg-6 col-12">
                <h1>Revisa si te encuentras en alguna de las 3 causales</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-purple400">Esta sección es importante. Lee con atención verifica y elige la causal o causales en las que te encuentres</p>
                  <p>La <strong>Ley N° 21.030</strong> establece que es posible realizar un aborto en los servicios de salud, siempre y cuando el embarazo se encuentre en alguna de las 3 causales (de manera gratuita a usuarias de FONASA y con un copago a quienes están en ISAPRE)</p>
                  <p>Revisa a continuación las causales y confirma si te encuentras en alguna de ellas. A través de tu informe ecográfico podrías detectar si estás en causal 1 o 2.</p>
                </article>
                <CausalesComponent />
              </div>
              <aside className="hide-tablet col-lg-6 col-12 d-flex justify-content-end">
                <figure className="image-270">
                  <img src="/assets/images/illustration/computer.png" alt="Te acompañamos" />
                </figure>
              </aside>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default CausalesScreen;