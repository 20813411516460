import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface StopConfirmProps {
  show: boolean;
  onHide: () => void;
}

const StopConfirmComponent: React.FC<StopConfirmProps> = (props) => {



  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="modal--md"
    >
     
      <Modal.Body className="text-center">
        <div className="d-flex justify-content-center">
        <figure className="image-64 mb-4">
            <img src="/assets/images/icons/checked.svg" alt="Confirmación" />
          </figure>
        </div>
        <h2 className="text-40 mb-4">Tu solicitud para abandonar el proceso ha sido procesada y aceptada</h2>
        <p className="text-24">Tu cuenta y toda la información fue eliminada de nuestra base de datos.</p>
        <p className="text-24">Si esto es un error <strong>deberás registrarte nuevamente</strong></p>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 text-center mb-4">
          <a
            href="/mi-proceso"
            className="btn btn--type1 btn--290"
          >Salir</a>
        </div>
        <div className="col-12 text-center mb-4">
          <a href="/crear-cuenta"
            className="btn--simple text-purple400"
          >Volver a registrárme</a>
        </div>
       
      </Modal.Footer>
    </Modal>
  );
};

export default StopConfirmComponent;
