import React from 'react';
import { Alert } from 'react-bootstrap';

interface CardInfoItem {
  image?: string;
  text?: React.ReactNode; 
}

interface CardInfoProps {
  title?: React.ReactNode;
  alert?: React.ReactNode;
  type?: string; 
  items: CardInfoItem[]; // Array de ítems
}

const CardInfoComponent: React.FC<CardInfoProps> = ({title, items, alert, type}) => {
  // Implement your component logic here

  return (
    <div className={`info-card ${type || ''}`}>
      {title ?  <div className="info-card__head">
        <p className="text-24 mb-0">{title}</p>
      </div> : null}
     
      <div className="info-card__body">
          {items.map((item, index) => (
            <div className="info-card__item text-center" key={index}>
              <img src={item.image} alt="icon" className="mb-2"/>
              <p>{item.text}</p>
            </div>
          ))}
          {alert ?  <Alert variant="danger" className="mt-4 mb-0 alert--soft-danger alert--icon alert--complete">
          <p className="text-center d-flex justify-content-center col-12"><span className="material-icons-outlined text-32">report_problem</span></p>
          <p className="text-center">{alert}</p>

        </Alert>: null}
        
       
      </div>
    </div>
  );
};

export default CardInfoComponent;