import React, { useState } from 'react';
import data from '../../../utils/comunas-regiones.json';
import nationData from '../../../utils/nacionalidad.json';
import { useNavigate } from 'react-router-dom';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



const SocioDemographicsComponent = () => {
  const navigate = useNavigate();
  const [age, setAge] = useState(''); 
  const [nationality, setNationality] = useState<string>('Chile'); // Estado para la nacionalidad
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [comunas, setComunas] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nation, setNation] = useState(nationData);
  
  const [selectedComuna, setSelectedComuna] = useState<string>(''); 
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);


  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAge = e.target.value;

    setAge(e.target.value ? e.target.value : '');
    if (/^\d{0,2}$/.test(newAge)) {
      setAge(newAge);
    }
  };

  const handleNationalityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNationality(e.target.value);
  };

  const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const region = e.target.value;
    setSelectedRegion(region);
    const regionData = data.regiones.find(r => r.region === region);
    setComunas(regionData ? regionData.comunas : []);
    setSelectedComuna(''); // Resetea la comuna seleccionada cuando cambia la región
  };

  const handleComunaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedComuna(e.target.value);
  };
  const handleKeyPress = (e: React.KeyboardEvent) => {
    // Permitir solo números
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleSubmit = () => {
    navigate('/causales');
  };

  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };

  return (
    <>
     <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
          />
       <div className="form-block">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label htmlFor="age" className="form-label label--icon">
                Tu edad actual <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es importante para nosotras generar estadísticas generales sobre quiénes abortan y en que etapa de sus vidas lo hicieron. Estas estadísticas ayudan a despenalizar socialmente a quienes abortan. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <input
                type="text"
                className="form-control"
                id="age"
                placeholder="Ingresa tu edad actual"
                value={age}
                onChange={handleAgeChange}
                maxLength={2}
                pattern="\d*"
                onKeyPress={handleKeyPress} 
              />
            </div>
            <div className="form-row">
              <label htmlFor="nation" className="form-label label--icon">
                Tu nacionalidad <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es importante para nosotras generar estadísticas generales sobre quiénes abortan y cuáles son sus países de origen.  Estas estadísticas ayudan a despenalizar socialmente a quienes abortan. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Tu nacionalidad"
                onChange={handleNationalityChange}
                value={nationality}
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {nation.map((country) => (
                  <option key={country.id} value={country.id}>{country.label}</option>
                ))}
              </select>
            </div>
            
            {nationality === "Otros" && (
                <div className="form-row">
                <label htmlFor="age" className="form-label label--icon">
                  Ingresa tu nacionalidad
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="otherNation"
                />
                </div>

            )}
           

            <div className="form-row">
              <label htmlFor="region" className="form-label label--icon">
                Tu región <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es importante para nosotras saber en cuál territorio te ubicas, con el objetivo de brindarte información que manejemos respecto a servicios de salud disponibles en tu territorio. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Tu región"
                onChange={handleRegionChange}
                defaultValue={'default'}
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {data.regiones.map((region, index) => (
                  <option key={index} value={region.region}>{region.region}</option>
                ))}
              </select>
            </div>
        
            <div className="form-row">
              <label htmlFor="comuna" className="form-label label--icon">
                Tu comuna <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Es importante para nosotras saber en cuál  territorio te ubicas, con el objetivo de brindarte información que manejemos respecto a servicios de salud disponibles en tu territorio. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Tu comuna"
                onChange={handleComunaChange}
                defaultValue={'default'}
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {comunas.map((comuna, index) => (
                  <option key={index} value={comuna}>{comuna}</option>
                ))}
              </select>
            </div>
        
            <div className="form-row module-top40 form--last">
              <button
                type="submit"
                className="btn btn--type1"
                disabled={age === '' || nationality === '' || selectedRegion === '' || selectedComuna === ''}
              >Guardar y continuar</button>
            </div>
            <div className="form-row form--last">
            <button
                type="button"
                className="btn--danger"
                onClick={() => setModalShow(true)}
              >Borrar mi registro</button>
            </div>
          </form>
        </div>
    </>
  );
}

export default SocioDemographicsComponent;