import React, {useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import StepperComponent from '../../../components/Stepper/stepper.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import SaludEmocionesComponent from '../../../components/Auth/SaludEmociones/saludEmociones.component';

const SaludEmocionesScreen: React.FC = () => {
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [activeStep, setActiveStep] = useState(6); // Estado para el paso actual
  return (
    <>
    <HeaderShared />
    <section className="module container">
      <div className="body-row row">
        <div className="col-12">
          <div className="module-bottom row">
            <div className="col-12">
              <PrivacyComponent />
            </div> 
          </div>
          <div className="auth-row module-bottom row">
            <div className="module-bottom40 col-12">
            <StepperComponent activeStep={activeStep} />
            </div>
            <div className="col-lg-5 col-12">
              <h1>Salud y Emociones</h1>
              <article className="text-20 col-12 col-reset">
                <p className="text-green350">Quédate tranquila, tus datos siempre están protegidos.</p>
                <p>Las siguientes preguntas buscan conocer información clave sobre tu <strong>estado emocional</strong> para poder acompañarte de mejor manera durante este proceso. </p>
                <p>Recuerda que tienes <strong>derecho a un procedimiento seguro</strong>, informado y libre de prejuicios.</p>
              </article>
             <SaludEmocionesComponent />
              
            </div>
            <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
          </div>
          
        </div>
      </div>
    </section>
    <FooterShared />
  </>
  );
};

export default SaludEmocionesScreen;