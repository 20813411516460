import React, {useState, useEffect} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';


const SaludEmocionesComponent = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formularioValido, setFormularioValido] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const [mostrarEnfermedadDetail, setEnfermedadDetail] = useState(false);
  const [mostrarDiscapacidadDetail, setDiscapacidadDetail] = useState(false);
  const [mostrarPsicologoDetail, setPsicologoDetail] = useState(false);
  const [mostrarAlcoholDetail, setAlcoholDetail] = useState(false);


  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
      } else {
        // Si el campo es el textarea y está oculto, lo ignoramos
        if (
          campo.id === "enfermedadDetail" && !mostrarEnfermedadDetail ||
          campo.id === "discapacidadDetail" && !mostrarDiscapacidadDetail ||
          campo.id === "psicologoDetail" && !mostrarPsicologoDetail ||
          campo.id === "alcoholDetail" && !mostrarAlcoholDetail
        ) {
          return;
        }
  
        const campoCompleto = campo.value.trim() !== '';
        todosCompletos = todosCompletos && campoCompleto;
      }
    });
  
    gruposRadio.forEach(nombre => {
      const seleccionado = document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;
    });
  
    setFormularioValido(todosCompletos);
  };
  
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxEnfermedadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mostrar = e.target.value === 'si';
    setEnfermedadDetail(mostrar);
    if (mostrar) {
      verifyFields();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxDiscapacidadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mostrar = e.target.value === 'si';
    setDiscapacidadDetail(mostrar);
    if (mostrar) {
      verifyFields();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxPsicologoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mostrar = e.target.value === 'si';
    setPsicologoDetail(mostrar);
    if (mostrar) {
      verifyFields();
    }
  };
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxAlcoholChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mostrar = e.target.value === 'si';
    setAlcoholDetail(mostrar);
    if (mostrar) {
      verifyFields();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxDeadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTextareaEnfermedadDetailChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    verifyFields();
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTextareaDiscapacidadDetailChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    verifyFields();
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTextareaPsicologoDetailChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    verifyFields();
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTextareaAlcoholDetailChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    verifyFields();
  };

  
  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };


  useEffect(() => {
    verifyFields();
  }, [mostrarEnfermedadDetail, mostrarDiscapacidadDetail, mostrarPsicologoDetail, mostrarAlcoholDetail]);

  const handleSubmit = () => {
    navigate('/sexualidad-y-emociones');
  };

  return (
    <>
     <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
          />
       <div className="form-block">
          <form onSubmit={handleSubmit}>

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Tienes alguna otra enfermedad o situación de salud que quieras compartir con nostras? <small className="required">*</small> <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      La causal 1 de la ley 21.030 contempla un listado de enfermedades en los cuales es posible acceder a un aborto en servicios de salud. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="enfermedad" id="enfermedadSi" value="si"  onChange={handleCheckboxEnfermedadChange}/>
                  <label className="form-check-label" htmlFor="enfermedadSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="enfermedad" id="enfermedadNo" value="no"  onChange={handleCheckboxEnfermedadChange}/>
                  <label className="form-check-label" htmlFor="enfermedadNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="enfermedad" id="enfermedadNoLoSe" value="no-estoy-segura"  onChange={handleCheckboxEnfermedadChange}/>
                  <label className="form-check-label" htmlFor="enfermedadNoLoSe">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>
            
            {mostrarEnfermedadDetail&& (
              <div className="form-row">
                <label className="form-label">Especifica cuál:</label>
                <textarea
                  className="form-control requiredField "
                  placeholder="Escribe aquí el diagnóstico y notas adicionales" id="enfermedadDetail"
                  onChange={handleTextareaEnfermedadDetailChange}
                ></textarea>
              </div>
            )}

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Estas en una situación de discapacidad? <small className="required">*</small> <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Esta pregunta nos permite conocer mejor tu situación y brindarte un acompañamiento adecuado. En algunos casos, la situación de discapacidad, podría coincidir con la causal 1 de la ley 21.030 de interrupción voluntaria del embarazo.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="discapacidad" id="discapacidadSi" value="si"  onChange={handleCheckboxDiscapacidadChange}/>
                  <label className="form-check-label" htmlFor="discapacidadSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="discapacidad" id="discapacidadNo" value="no"  onChange={handleCheckboxDiscapacidadChange}/>
                  <label className="form-check-label" htmlFor="discapacidadNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="discapacidad" id="discapacidadNoLoSe" value="no-estoy-segura"  onChange={handleCheckboxDiscapacidadChange}/>
                  <label className="form-check-label" htmlFor="discapacidadNoLoSe">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>
            
            {mostrarDiscapacidadDetail&& (
              <div className="form-row">
                <label className="form-label">Especifica cuál:</label>
                <textarea
                  className="form-control requiredField "
                  placeholder="Escribe aquí el diagnóstico y notas adicionales" id="discapacidadDetail"
                  onChange={handleTextareaDiscapacidadDetailChange}
                ></textarea>
              </div>
            )}
           
            <div className="form-row">
              <label className="form-label label--icon">
              ¿Tienes algún diagnóstico psicológico o psiquiátrico? <small className="required">*</small> <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Esta pregunta nos permite conocer mejor tu situación y brindarte un acompañamiento adecuado.<br/>

En algunos casos el diagnóstico psicológico o psiquiátrico, podría  corresponder a la causal 1 de la ley 21.030, de interrupción voluntaria de embarazo.


                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="psicologo" id="psicologoSi" value="si"  onChange={handleCheckboxPsicologoChange}/>
                  <label className="form-check-label" htmlFor="psicologoSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="psicologo" id="psicologoNo" value="no"  onChange={handleCheckboxPsicologoChange}/>
                  <label className="form-check-label" htmlFor="psicologoNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="psicologo" id="psicologoNoLoSe" value="no-estoy-segura"  onChange={handleCheckboxPsicologoChange}/>
                  <label className="form-check-label" htmlFor="psicologoNoLoSe">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>
            
            {mostrarPsicologoDetail&& (
              <div className="form-row">
                <label className="form-label">Especifica cuál:</label>
                <textarea
                  className="form-control requiredField "
                  placeholder="Escribe aquí el diagnóstico y notas adicionales" id="psicologoDetail"
                  onChange={handleTextareaPsicologoDetailChange}
                ></textarea>
              </div>
            )}

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Consideras que tienes algún grado de consumo problemático de drogas y/o alcohol? <small className="required">*</small> <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Esta pregunta nos permite conocer mejor la situación y brindar un acompañamiento adecuado.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="alcohol" id="alcoholSi" value="si"  onChange={handleCheckboxAlcoholChange}/>
                  <label className="form-check-label" htmlFor="alcoholSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="alcohol" id="alcoholNo" value="no"  onChange={handleCheckboxAlcoholChange}/>
                  <label className="form-check-label" htmlFor="alcoholNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="alcohol" id="alcoholNoLoSe" value="no-estoy-segura"  onChange={handleCheckboxAlcoholChange}/>
                  <label className="form-check-label" htmlFor="alcoholNoLoSe">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>
            
            {mostrarAlcoholDetail&& (
              <div className="form-row">
                <label className="form-label">Especifica cuál:</label>
                <textarea
                  className="form-control requiredField "
                  placeholder="Escribe aquí el diagnóstico y notas adicionales" id="alcoholDetail"
                  onChange={handleTextareaAlcoholDetailChange}
                ></textarea>
              </div>
            )}
           
           <div className="form-row">
              <label className="form-label label--icon">
              Hay muchas mujeres que refieren deseo o ganas de morir a razón de un embarazo no deseado, ¿Te encuentras en esta situación? <small className="required">*</small> <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Esta pregunta nos permite conocer mejor la  situación y brindar un acompañamiento adecuado.<br/>

Los deseos o ganas de morir, podrían ser diagnosticados como ideación o intento suicida a razón o intensificado por el embarazo.<br/>

En estos casos es posible acceder a un aborto por causales en los servicios de salud, de acuerdo a la ley 21.030, de interrupcion voluntaria del embarazo. 


                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="dead" id="deadSi" value="si"  onChange={handleCheckboxDeadChange}/>
                  <label className="form-check-label" htmlFor="deadSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="dead" id="deadNo" value="no"  onChange={handleCheckboxDeadChange}/>
                  <label className="form-check-label" htmlFor="deadNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="dead" id="deadNoLoSe" value="no-estoy-segura"  onChange={handleCheckboxDeadChange}/>
                  <label className="form-check-label" htmlFor="deadNoLoSe">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row module-top40">
              <p className="text-center">¡Y recuerda! Si lo necesitas podemos acordar una llamada</p>
              <a href="tel:569982729131" className="btn btn--type2 btn--icon">
                <i className="icon icon--phone-call"></i>
                Información de aborto legal en Chile 569 982 729 131
              </a>
            </div>
            <div className="form-row  form--last">
              <button
                type="submit"
                className="btn btn--type1"
                disabled={!formularioValido}
              >Guardar y continuar</button>
            </div>
            <div className="form-row form--last">
            <button
                type="button"
                className="btn--danger"
                onClick={() => setModalShow(true)}
              >Borrar mi registro</button>
            </div>
          </form>
        </div>
    </>
  );
}

export default SaludEmocionesComponent;