import React, {useState, useEffect} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const EvaluacionNoHospitalComponent: React.FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formularioValido, setFormularioValido] = useState(false);

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    let todosCompletos = true;
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        // Verificar si al menos uno del grupo está seleccionado
        const grupoSeleccionado = !!document.querySelector(`input[name="${campo.name}"]:checked`);
        todosCompletos = todosCompletos && grupoSeleccionado;
      } else {
        const campoCompleto = campo.value.trim() !== '';
        todosCompletos = todosCompletos && campoCompleto;
      }
    });
  
    setFormularioValido(todosCompletos);
  }
  
    

  useEffect(() => {
    verifyFields();
  }, []);


  const handleSubmit = () => {
    navigate('/seguimiento');
  };

  return (
    <>
   
      <div className="row">
        <div className="col-md-5 col-12">
          <h2 className="text-green900 text-40 mb-3">¿Decidiste no ir a un hospital?</h2>
          <article className="text-20">
            <p>Nos interesa saber tus razones, de esta forma podemos encontrar una solución juntas o bien, cerrar el procedimiento correctamente.</p>
         
            <p className="text-green350"><strong>Recuerda que este proceso, así como tus datos están protegidos.</strong></p>

          </article>
          <div className="form-block">
            <form onSubmit={handleSubmit}>
           
            <div className="form-row">
              <p className="text-20 mb-0">
                No fui al hospital porque:
              </p>
              <p>Elige las respuestas que correspondan a tu situación:</p>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="abortoEspontaneo" value="Tuve un aborto espontáneo"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="abortoEspontaneo">Tuve un aborto espontáneo</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="trabajo" value="Temo perder mi trabajo actual"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="trabajo">Temo perder mi trabajo actual</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="migrante" value="Soy migrante y pienso que esto me podría perjudicar"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="migrante">Soy migrante y pienso que esto me podría perjudicar</label>
                </div>
              </div>
            
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="violencia" value="Por miedo a la violencia obstétrica"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="violencia">Por miedo a la violencia obstétrica</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="expulsada" value="Por miedo a ser expulsada del hogar"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="expulsada">Por miedo a ser expulsada del hogar</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="conyuge" value="Por miedo a mi cónyuge y/o agresor"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="conyuge">Por miedo a mi cónyuge y/o agresor</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="castigada" value="Temo ser juzgada y/o castigada por la familia"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="castigada">Temo ser juzgada y/o castigada por la familia</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="juicio" value="Miedo a un proceso judicial"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="juicio">Miedo a un proceso judicial</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="procedimiento" value="Realicé mi procedimiento con alguien más"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="procedimiento">Realicé mi procedimiento con alguien más</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="complicacion" value="Considero complicada la ubicación del hospital"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="complicacion">Considero complicada la ubicación del hospital</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="horario" value="Es complicado realizarlo por mi horario personalxto"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="horario">Es complicado realizarlo por mi horario personal</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="hijos" value="No tengo con quién dejar encargados a mis hijos para enfrentar una hospitalización"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="hijos">No tengo con quién dejar encargados a mis hijos para enfrentar una hospitalización</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="recursos" value="No tengo recursos económicos para un traslado"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="recursos">No tengo recursos económicos para un traslado</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="cambioOpinion" value="Cambié de opinión al final"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="cambioOpinion">Cambié de opinión al final</label>
                </div>
              </div>
              <div className="form-block row-check-2 mb-4">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="otro" value="Por otras razones"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="otro">Por otras razones</label>
                </div>
              </div>
           
            </div>
            <div className="form-row">
            <label className="form-label label--icon">
            Ingresa una razón adicional 
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                    Ingresa una razón adicional
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <textarea className="form-control" id="otherUrgencia" rows={3} placeholder='¿Alguna que quieras agregar?'  onChange={verifyFields}></textarea>
            </div>
              <div className="form-row  form--last">
                <button
                  type="submit"
                  className="btn btn--type1"
                  disabled={!formularioValido}
                >Siguiente</button>
              </div>
            </form>
          </div>
        </div>
        <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-end">
          <figure className="image-350">
            <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
          </figure>
        </aside>
      </div>
    </>
  );
};

export default EvaluacionNoHospitalComponent;
