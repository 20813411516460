import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';

interface StopProcessProps {
  show: boolean;
  onHide: () => void;
  onDelete: () => void; 
}

const StopProcessComponent: React.FC<StopProcessProps> = ({ show, onHide, onDelete }) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = () => {
    // Verifica si al menos un checkbox está marcado
    const isChecked = document.querySelectorAll('input[type="checkbox"]:checked').length > 0;
    setIsCheckboxChecked(isChecked);
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header  className="justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          <h2>Detener el proceso</h2>
          <p className="text-24">Recuerda registrar los detalles por los que la mujer decide cambiar o abandonar el procedimiento</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Qué motivos son los mencionados por la mujer para abandonar el procedimiento? <small className="required">*</small>
            </label>
            <p className="text-gray800">Puedes seleccionar más de una opción si es tu caso</p>
            <div className="row-check-2 pt-4 row">
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input className="form-check-input requiredField checkbox--green" type="checkbox" onChange={handleCheckboxChange} name="metodoComprobacion" id="complicado" value="Lo considera complicado" />
                  <label className="form-check-label" htmlFor="complicado">
                  Lo considera complicado
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input className="form-check-input requiredField checkbox--green" type="checkbox" onChange={handleCheckboxChange} name="metodoComprobacion" id="desdeCasa" value="Solicita hacerlo desde su casa" />
                  <label className="form-check-label" htmlFor="desdeCasa">
                  Solicita hacerlo desde su casa
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input className="form-check-input requiredField checkbox--green" type="checkbox" onChange={handleCheckboxChange} name="metodoComprobacion" id="mensajes" value="Dejó de contestar los mensajes" />
                  <label className="form-check-label" htmlFor="mensajes">
                  Dejó de contestar los mensajes
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input className="form-check-input requiredField checkbox--green" type="checkbox" onChange={handleCheckboxChange} name="metodoComprobacion" id="problemas" value="Tuve problemas con ella" />
                  <label className="form-check-label" htmlFor="problemas">
                  Tuve problemas con ella
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input className="form-check-input requiredField checkbox--green" type="checkbox" onChange={handleCheckboxChange} name="metodoComprobacion" id="continuar" value="No está segura de continuar" />
                  <label className="form-check-label" htmlFor="continuar">
                  No está segura de continuar
                  </label>
                </div>
              </div>
              <div className="row-check-2__col col-6">
                <div className="form-check">
                  <input className="form-check-input requiredField checkbox--green" type="checkbox" onChange={handleCheckboxChange} name="metodoComprobacion" id="otro" value="Otro motivo no especificado" />
                  <label className="form-check-label" htmlFor="otro">
                  Otro motivo no especificado
                  </label>
                </div>
              </div>
            </div>
            
          </div>
          <div className="form-row">
            <label className="form-label label--icon text-gray800">
            ¿Qué observaciones necesitan saber en el equipo de Las Amigas?
            </label>
            <textarea className="form-control form--green" id="other" rows={3} placeholder="Ingresa motivos adicionales o situaciones que puedan ayudar a Las Amigas a entender el porqué de esta decisión."></textarea>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
      
        <div className="col-12 text-center mb-4">
          <button
              type="button"
              onClick={() => {
                onDelete();
                onHide();
              }}
              disabled={!isCheckboxChecked}
              className="btn btn--type1 btn--290"
            >
              Detener el proceso
            </button>
            
          </div>
          <div className="col-12 text-center mb-4">
            <button type="button" onClick={onHide} className="btn btn--type2 btn--290">
              Cancelar
            </button>
            
          </div>
      </Modal.Footer>
    </Modal>
  );
};

export default StopProcessComponent;
