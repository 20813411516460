import React from 'react';

interface Props {
  activeStep: number; // Prop para indicar el paso actual
}

const StepperComponent: React.FC<Props> = ({ activeStep }) => {
  const getStepText = (step: number) => {
    // Función para mapear el paso actual a un texto correspondiente
    switch (step) {
      case 1:
        return 'Paso 1';
      case 2:
        return 'Paso 2';
      case 3:
        return 'Paso 3';
      case 4:
        return 'Paso 4';
      case 5:
        return 'Paso 5';
      case 6:
        return 'Paso 6';
      case 7:
        return 'Paso 7';
      default:
        return '';
    }
  };

  const getStepWidth = (step: number) => {
    // Función para determinar el ancho del stepper__item
    const totalSteps = 7; // Número total de pasos
    let width = (step / totalSteps) * 100; // Calcula el ancho en porcentaje

    if (step === 7) {
      width = 97; // Si el paso es 7, establece el ancho en 97%
    }

    return `${width}%`;
  };

  return (
    <section className="stepper">
      <div className="stepper__container" style={{ width: getStepWidth(activeStep) }}>
        <div className="stepper__figure">
            <p className="stepper__text">{getStepText(activeStep)}</p>
          </div>
      </div>
    </section>
  );
};

export default StepperComponent;
