import React from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import Step1Component from '../../../components/Process/Step1/step1.component';

interface Props {
  // Define your component props here
}

const ProcessAcceptedScreen: React.FC<Props> = () => {
  const steps = [
    { title: "Completa tu perfil", description: "Registrada", status: "status--success", completed: true, color: "green350" },
    { title: "Te presentamos a tu acompañante", description: "En proceso", status: "status--process", completed: false, color: "pink400" },
    { title: "Llamada telefónica", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Accede a la ley 21.030", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Seguimos en contacto", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Déjanos tu opinión", description: "Pendiente", status: "", completed: false, color: "gray600" },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h1 className="text-64 mb-3">Hola, Paulina</h1>
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h2 className="h1">Este es el camino en tu proceso</h2>
                <ProcessStatusComponent steps={steps} currentStepIndex={0} /> 
              </div>
             <Step1Component accepted={true}/>
            </div>
           
           
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default ProcessAcceptedScreen;