import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  // Define your component props here
};

const HeaderShared: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLogged, setIsLogged] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isNotificationOn, setNotificationOn] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();

  const handleButtonClick = () => {
    setIsOpen(!isOpen); // Cambia el estado al opuesto de lo que está actualmente
  };

  const isActive = (path: string): string => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <header>
      <div className="container">
        <div className="header__col col-12">
          <a href="/" className="header__logo">
              <figure>
                <img
                  src={`${
                    isOpen
                      ? '/assets/images/logo/ola-white.png'
                      : '/assets/images/logo/ola.png' 
                  }`}
                  alt="OLA Chile"
                />
              </figure>
            </a>
            <nav className={`main-nav ${isOpen ? 'active' : ''}`}>
              <ul>
              {isLogged ?  <>
              
                <li className="main-nav__mobile">
                  <div className="header-options__mobile">
                    <div className="header-options__top">
                      {isNotificationOn ? <a href="/" className="notifications">
                        <i className="icon icon--notification-on-white"></i>
                      </a> : ''}
                      
                      <a href="/" className="user-control-button">
                        <i className="icon icon--user-small"></i>
                      </a>
                    </div>
                    {isNotificationOn ?  <div className="header-alert justify-content-center">
                      <span className="icon material-icons-outlined">report_problem</span>
                      <p>Alguien más accedió a mi perfil</p>
                    </div>: ''}
                  
                  </div>
                </li>
                <li>
                  <a href="/mi-proceso" className={isActive('/mi-proceso')}>Tu proceso</a>
                </li>
                <li>
                  <a href="https://olachile.org/" target='_blank' rel="noreferrer" >Sobre nosotras</a>
                </li>
                <li>
                  <a href="/quiero-donar" className={isActive('/quiero-donar')}>
                    Donar a OLA
                  </a>
                </li>
              </> : <>
              <li>
                <a href="/" className={isActive('/')}>
                  Quiero ser gestora comunitaria
                </a>
                </li>
                <li>
                  <a href="/quiero-donar" className={isActive('/quiero-donar')}>
                    Quiero donar
                  </a>
                </li>
                <li>
                  <a
                    href="/iniciar-sesion"
                    className={isActive('/iniciar-sesion')}
                  >
                    Iniciar sesión
                  </a>
                </li>
                <li>
                  <a
                    href="/registro"
                    className={isActive('/registro')}
                  >
                    Registrate
                  </a>
                </li>
              
              </>}
             
                {isLogged ? <>
                  <li className="main-nav__mobile">
                  <a href="/" className={isActive('/dona')}>Ver proceso</a>
                </li>
                <li className="main-nav__mobile">
                  <a href="/" className={isActive('/dona')}>Cerrar sesión</a>
                </li>
                </> : ''}
              </ul>
            </nav>
            {isLogged ? <div className="header__options">
          {isNotificationOn ?  <>
            <div className="header-alert">
              <span className="icon material-icons-outlined">report_problem</span>
              <p>Alguien más accedió a mi perfil</p>
            </div>
            <a href="/" className="notifications">
              <i className="icon icon--notification-on"></i>
            </a>
          </> : ''}
           
            <div className="user-control">
              <button type="button" className="user-control-button">
                <i className="icon icon--user-small"></i>
              </button>
              <div className="user-options">
                <div className="user-options__top">
                  <i className="icon icon--user-big"></i>
                  <article>
                    <h3 className="text-regular text-20">Paulina Pimentel</h3>
                    <a href="/">Ver perfil</a>
                  </article>
                </div>
                <div className="user-options__bottom">
                  <ul>
                    <li>
                      <a href="/mi-proceso">
                        <i className="icon icon--search"></i>
                        <span>Ver proceso</span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="icon icon--logout"></i>
                        <span>Cerrar sesión</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> : '' }
            <button
              type="button"
              className={`open-nav ${isOpen ? 'active' : ''}`}
              onClick={handleButtonClick}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderShared;