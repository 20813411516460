import React from 'react';
import HeaderShared from '../../../../shared/header.shared';
import PrivacyComponent from '../../../../components/Banners/Privacy/privacy.component';
import FooterShared from '../../../../shared/footer.shared';

interface Props {
  // Define your component props here
}

const ChatAcompananteScreen: React.FC<Props> = () => {
  
  return (
    <>
      <HeaderShared />
      <section className="module-top container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom40 row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
          
            <div className="module-bottom40 row">
              <div className="col-12">
                <div className="chatbox-placeholder"></div>
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12 text-center">
                <a href="/acompanamiento" className="btn btn--type1">Ir a mi registro de procedimiento</a>
               
              </div>
              
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default ChatAcompananteScreen;