import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface DeleteAccountProps {
  show: boolean;
  onHide: () => void;
  onDelete: () => void; 
}

const DeleteAccountComponent: React.FC<DeleteAccountProps> = ({ show, onHide, onDelete }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="justify-content-center pb-0">
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          <h2>Estás a punto de borrar toda la información</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p className="text-24">Todos los datos que acabas de ingresar se borrarán de nuestra base y tendrás que ingresarlos nuevamente.</p>
      </Modal.Body>
      <Modal.Footer>
      
        <div className="col-12 text-center mb-4">
        <button
            type="button"
            onClick={() => {
              onDelete();
              onHide();
            }}
            className="btn btn--delete"
          >
            Eliminar toda la información
          </button>
          
        </div>
        <div className="col-12 text-center mb-4">
          <button type="button" onClick={onHide} className="btn--simple text-purple400">
            Regresar
          </button>
          
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAccountComponent;
