import React, {useState, useEffect} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';


const SexualidadEmocionesComponent = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formularioValido, setFormularioValido] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);


  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
        console.log(campo)
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
          console.log(campoCompleto)
        }
      }
    });
  
    gruposRadio.forEach(nombre => {
      const seleccionado = document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;

      console.log(seleccionado)
    });
  
    setFormularioValido(todosCompletos);
  };
  
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxConsentimientoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };

 
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxConfianzaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxEresChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxRelacionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxAgresionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };
  
  
  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };


  useEffect(() => {
    verifyFields();
  }, []);

  const handleSubmit = () => {
    navigate('/violentometro');
  };

  return (
    <>
     <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
          />
       <div className="form-block">
          <form onSubmit={handleSubmit}>

            <div className="form-row">
              <label className="form-label label--icon">
              ¿La relación sexual ocurrió con tu 
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Si la relación ocurrió sin consentimiento o por fuera de lo acordado, el embarazo se encuentra en causal 3 de la ley 21.030 de interrupcion voluntaria del embarazo. <br/>Garantiza el derecho de acceder a un aborto en los servicios de salud y a un Programa de acompañamiento psicosocial. (linkear a la norma tecnica de acompañamiento psicosocial)


                    </Tooltip>
                  }
                >
                 <span>{' '}<strong className="text-green400 text-underline">consentimiento</strong></span>
                </OverlayTrigger>
              ? <small className="required">*</small> 
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="consentimiento" id="consentimientoSi" value="si"  onChange={handleCheckboxConsentimientoChange}/>
                  <label className="form-check-label" htmlFor="consentimientoSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="consentimiento" id="consentimientoNo" value="no"  onChange={handleCheckboxConsentimientoChange}/>
                  <label className="form-check-label" htmlFor="consentimientoNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="consentimiento" id="consentimientoNoLoSe" value="no-estoy-segura"  onChange={handleCheckboxConsentimientoChange}/>
                  <label className="form-check-label" htmlFor="consentimientoNoLoSe">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>
            
            <div className="form-row">
                <label className="form-label text-gray600 label--icon">Si necesitas hablar más sobre el tema podrás hacerlo con tu acompañante al terminar de registrarte, ¡Recuerda que estás en un espacio seguro!  <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Si la relación ocurrió sin consentimiento o por fuera de lo acordado, el embarazo se encuentra en causal 3 de la ley 21.030 de interrupcion voluntaria del embarazo. <br/>

Garantiza el derecho de acceder a un aborto en los servicios de salud y a un Programa de acompañamiento psicosocial. (linkear a la norma tecnica de acompañamiento psicosocial)



                    </Tooltip>
                  }
                >
                <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger></label>
                <textarea
                  className="form-control "
                  placeholder="Cuéntanos por aquí" id="consentinimientoDetail"
                ></textarea>
              </div>

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Te sentiste forzada a tener relaciones sexuales? <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Sentirse forzada no siempre implica fuerza física por parte de los agresores.<br />

Si te sentiste forzada a tener relaciones sexuales,  el embarazo se encuentra en causal 3 de la ley 21.030 de interrupcion voluntaria del embarazo.<br /> 

Garantiza el derecho de acceder a un aborto en los servicios de salud y a un Programa de acompañamiento psicosocial. (linkear a la norma tecnica de acompañamiento psicosocial)

                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="forzada" id="forzadaSi" value="si"/>
                  <label className="form-check-label" htmlFor="forzadaSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="forzada" id="forzadaNo" value="no"/>
                  <label className="form-check-label" htmlFor="forzadaNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="forzada" id="forzadaNoLoSe" value="no-estoy-segura"/>
                  <label className="form-check-label" htmlFor="forzadaNoLoSe">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>
            
            <div className="form-row">
                <label className="form-label text-gray600">Si necesitas hablar más sobre el tema podrás hacerlo con tu acompañante al terminar de registrarte, ¡Recuerda que estás en un espacio seguro!</label>
                <textarea
                  className="form-control "
                  placeholder="Cuéntanos por aquí" id="forzadaDetail"
                ></textarea>
              </div>
           
            <div className="form-row">
              <label className="form-label label--icon">
              ¿Te sentiste obligada a tener relaciones sexuales? <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Muchas veces las mujeres se sienten obligadas a tener relaciones sexuales para evitar algún tipo de maltrato por parte de su pareja, ex pareja o padre de sus hijos/as. Esta obligatoriedad no siempre implica el uso de fuerza física, sino que más bien está atravesada por actos de manipulación emocional o económica. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="obligada" id="obligadaSi" value="si"/>
                  <label className="form-check-label" htmlFor="obligadaSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="obligada" id="obligadaNo" value="no"/>
                  <label className="form-check-label" htmlFor="obligadaNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="obligada" id="obligadaNoLoSe" value="no-estoy-segura"/>
                  <label className="form-check-label" htmlFor="obligadaNoLoSe">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>
            
            <div className="form-row">
                <label className="form-label text-gray600">Si necesitas hablar más sobre el tema podrás hacerlo con tu acompañante al terminar de registrarte, ¡Recuerda que estás en un espacio seguro!</label>
                <textarea
                  className="form-control "
                  placeholder="Cuéntanos por aquí" id="obligadaDetail"
                ></textarea>
              </div>

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Tuviste miedo? <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Si sentiste miedo al tener relaciones sexuales en las cuales quedaste embarazada o consentiste las relaciones por miedo a que algo malo o que no deseas te suceda, el embarazo se encuentra en causal 3 de la ley 21.030 de interrupción voluntaria del embarazo.<br/>

Esta ley garantiza el derecho de acceder a un aborto en los servicios de salud y a un Programa de acompañamiento psicosocial.
 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="miedo" id="miedoSi" value="si"/>
                  <label className="form-check-label" htmlFor="miedoSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="miedo" id="miedoNo" value="no"/>
                  <label className="form-check-label" htmlFor="miedoNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="miedo" id="miedoNoLoSe" value="no-estoy-segura"/>
                  <label className="form-check-label" htmlFor="miedoNoLoSe">
                    No estoy segura
                  </label>
                </div>
              </div>
            </div>
            
          
           <div className="form-row">
              <label className="form-label label--icon">
              ¿Tienes a una mujer de confianza que esté contigo durante el proceso? <small className="required">*</small> <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es muy importante estar acompañada durante todo el proceso por una mujer de confianza. Si no tienes a nadie de confianza, comunícalo a tu acompañante de manera oportuna para brindarte la compañía que necesitas. 


                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="confianza" id="confianzaSi" value="si"  onChange={handleCheckboxConfianzaChange}/>
                  <label className="form-check-label" htmlFor="confianzaSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="confianza" id="confianzaNo" value="no"  onChange={handleCheckboxConfianzaChange}/>
                  <label className="form-check-label" htmlFor="confianzaNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="confianza" id="confianzaNoLoSe" value="no-estoy-segura"  onChange={handleCheckboxConfianzaChange}/>
                  <label className="form-check-label" htmlFor="confianzaNoLoSe">
                    No estoy segura
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="confianza" id="confianzaSola" value="no-estoy-segura"  onChange={handleCheckboxConfianzaChange}/>
                  <label className="form-check-label" htmlFor="confianzaSola">
                    Prefiero hacerlo sola
                  </label>
                </div>
              </div>
            </div>


            <div className="form-row">
              <label className="form-label label--icon">
              ¿Quién o quienes? <small className="required">*</small> <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Conocer con anticipación quienes te acompañaran te permitirá planear y organizar de mejor manera tu proceso. Comparte con tu acompañante esta información para que en el caso de ser necesario podamos establecer contacto con estas personas, en el caso de ser necesario.

                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <p className="text-gray600">Puedes seleccionar más de una opción</p>
              <div className="row-check-2 row">
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="amiga" value="Amiga"  onChange={handleCheckboxEresChange}/>
                    <label className="form-check-label" htmlFor="amiga">
                    Amiga
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="tia" value="Tía"  onChange={handleCheckboxEresChange}/>
                    <label className="form-check-label" htmlFor="tia">
                    Tía
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="hermana" value="Hermana"  onChange={handleCheckboxEresChange}/>
                    <label className="form-check-label" htmlFor="hermana">
                    Hermana
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="mama" value="Mamá"  onChange={handleCheckboxEresChange}/>
                    <label className="form-check-label" htmlFor="mama">
                    Mamá
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="pareja" value="Pareja"  onChange={handleCheckboxEresChange}/>
                    <label className="form-check-label" htmlFor="pareja">
                    Pareja
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="vecina" value="Vecina"  onChange={handleCheckboxEresChange}/>
                    <label className="form-check-label" htmlFor="vecina">
                    Vecina
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="abuela" value="Abuela"  onChange={handleCheckboxEresChange}/>
                    <label className="form-check-label" htmlFor="abuela">
                    Abuela
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="prima" value="Prima"  onChange={handleCheckboxEresChange}/>
                    <label className="form-check-label" htmlFor="prima">
                    Prima
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="pareja" value="Pareja"  onChange={handleCheckboxEresChange}/>
                    <label className="form-check-label" htmlFor="pareja">
                    Pareja
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="eres" id="otra" value="Otra"  onChange={handleCheckboxEresChange}/>
                    <label className="form-check-label" htmlFor="otra">
                    Otra
                    </label>
                  </div>
                </div>
             
              </div>
             
            </div>


            <div className="form-row">
              <label className="form-label label--icon">
              ¿Actualmente estás en una relación heterosexual? <small className="required">*</small> <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Con fines de conocerte mejor nos gustaría saber quienes integran tu Red de Apoyo más cercana y por quien o quienes está integrada. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="relacion" id="relacionSi" value="si"  onChange={handleCheckboxRelacionChange}/>
                  <label className="form-check-label" htmlFor="relacionSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="relacion" id="relacionNo" value="no"  onChange={handleCheckboxRelacionChange}/>
                  <label className="form-check-label" htmlFor="relacionNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="relacion" id="relacionNoLoSe" value="no-estoy-segura"  onChange={handleCheckboxRelacionChange}/>
                  <label className="form-check-label" htmlFor="relacionNoLoSe">
                    No estoy segura
                  </label>
                </div>
                
              </div>
            </div>

            <div className="form-row">
              <label className="form-label label--icon">
              ¿Has sentido algún tipo de agresión/situación de violencia con  la persona que te embarazó? <small className="required">*</small> <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Si sientes agresión o violencia por parte de la persona que te embarazo es posible que tu embarazo se encuentra en causal 3 de la ley 21.030 de interrupción voluntaria del embarazo.<br/>

Esta ley garantiza el derecho de acceder a un aborto en los servicios de salud y a un Programa de acompañamiento psicosocial. 

                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="agresion" id="agresionSi" value="si"  onChange={handleCheckboxAgresionChange}/>
                  <label className="form-check-label" htmlFor="agresionSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="agresion" id="agresionNo" value="no"  onChange={handleCheckboxAgresionChange}/>
                  <label className="form-check-label" htmlFor="agresionNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="agresion" id="agresionNoLoSe" value="no-estoy-segura"  onChange={handleCheckboxAgresionChange}/>
                  <label className="form-check-label" htmlFor="agresionNoLoSe">
                    No estoy segura
                  </label>
                </div>
                
              </div>
            </div>

            <div className="form-row">
                <label className="form-label text-gray600">Si necesitas hablar más sobre el tema podrás hacerlo con tu acompañante al terminar de registrarte, ¡Recuerda que estás en un espacio seguro!</label>
                <textarea
                  className="form-control "
                  placeholder="Cuéntanos por aquí" id="agresionDetail"
                ></textarea>
              </div>

            <div className="form-row module-top40">
              <a href="tel:569982729131" className="btn btn--type2 btn--icon">
                <i className="icon icon--phone-call"></i>
                Información de aborto legal en Chile 569 982 729 131
              </a>
            </div>
            <div className="form-row  form--last">
              <button
                type="submit"
                className="btn btn--type1"
                disabled={!formularioValido}
              >Guardar y continuar</button>
            </div>
            <div className="form-row form--last">
            <button
                type="button"
                className="btn--danger"
                onClick={() => setModalShow(true)}
              >Borrar mi registro</button>
            </div>
          </form>
        </div>
    </>
  );
}

export default SexualidadEmocionesComponent;