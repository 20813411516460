import React, {useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import CardWrapperComponent from '../../../components/Cards/cardWrapp.component';
import cards from '../../../utils/cardsGestion2.json';
import StopProcessComponent from '../../../components/Modals/StopProcessModal/stopProcessModal.component';
import StopConfirmComponent from '../../../components/Modals/StopConfirmModal/stopConfirmModal.component';
import HospitalGridComponent from '../../../components/Hospitals/hospitalGrid.component';


interface Props {
  // Define your component props here
}

const GestionAprobacionScreen: React.FC<Props> = () => {
  const steps = [
    { title: "Completa tu perfil", description: "Registrada", status: "status--success", completed: true, color: "green350" },
    { title: "Te presentamos a tu acompañante", description: "En proceso", status: "status--success", completed: true, color: "green350" },
    { title: "Llamada telefónica", description: "Pendiente", status: "status--process", completed: false, color: "pink400" },
    { title: "Accede a la ley 21.030", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Seguimos en contacto", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Déjanos tu opinión", description: "Pendiente", status: "", completed: false, color: "gray600" },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalShow, setModalShow] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };
  return (
    <>
    <StopProcessComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <StopConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
        />
      <HeaderShared />
      <section className="module-top container">
        <div className="body-row row">
          <PrivacyComponent />
          <div className="col-12 col-reset">
            
           
            <div className="module-bottom40 ">
              <ProcessStatusComponent steps={steps} currentStepIndex={0} /> 
            </div>
           
            <div className="module-bottom40">
              <div className="text-center">
                <h1 className="text-52">Gestiona tu procedimiento</h1>
                <p className="text-24">Selecciona el hospital y dos opciones más para el procedimiento</p>
              </div>

            </div>
            <HospitalGridComponent />
           
          </div>
          
        </div>
      </section>
     
     
      <CardWrapperComponent
       color="base"
        title="Descarga aquí los pasos a seguir para un aborto por causales"
        titleSecond="¿Qué información necesitas antes de ir a un hospital a solicitar un aborto?"
        cards={cards}
      />
       <section className="module-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto col-12 d-flex justify-content-center mb-4">
              <a href="/" className="btn btn--type1 btn--100">Confirmar mi selección y continuar en el chat con mi gestora</a>
            </div>
            <div className="col-md-8 mx-auto col-12 d-flex justify-content-center">
            <button
              type="button"
              className="btn btn--type2 btn--100"
              onClick={() => setModalShow(true)}
            >Ya no necesito este acompañamiento</button>
              </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default GestionAprobacionScreen;