import React, {useState, useEffect} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Step4Component: React.FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formularioValido, setFormularioValido] = useState(false);

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    let todosCompletos = true;
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        // Verificar si al menos uno del grupo está seleccionado
        const grupoSeleccionado = !!document.querySelector(`input[name="${campo.name}"]:checked`);
        todosCompletos = todosCompletos && grupoSeleccionado;
      } else {
        const campoCompleto = campo.value.trim() !== '';
        todosCompletos = todosCompletos && campoCompleto;
      }
    });
  
    setFormularioValido(todosCompletos);
  }
  
    

  useEffect(() => {
    verifyFields();
  }, []);


  const handleSubmit = () => {
    navigate('/evaluacion-hospital');
  };

  return (
    <>
   
      <div className="row">
        <div className="col-md-5 col-12">
          <h2 className="text-green900 text-40 mb-3">Bitácora de cierre 
de acompañamiento</h2>
          <article className="text-20">
            
            <p>¡Hola! Hemos llegado casi al fin de este proceso.
Nos gustaría que pudieras compartir aquí un breve registro de tu experiencia</p>
            <p>Sólo compartiendo nuestras historias, contribuimos a documentar cómo es el acceso a la <strong>Ley IVE N° 21.030</strong>.</p>
            <p>Juntas podemos colaborar para mejorar los servicios de salud en Chile.</p>
            <p className="text-green350"><strong>Recuerda que este proceso, así como tus datos están protegidos.</strong></p>

          </article>
          <div className="form-block">
            <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label className="form-label label--icon">
              ¿Pudiste acceder un aborto a través de la implementación <strong>21.030</strong>? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                    Implementación 21.030
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-block">
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="ley21" id="si" value="si" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="si">
                    Sí
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="ley21" id="siPero" value="no" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="siPero">
                   Sí, pero no pude acceder en el primer servicio de salud donde intenté
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="ley21" id="no" value="no" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="no">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
              ¿Bajo qué causal/causales se realizó tu aborto? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     ¿Bajo qué causal/causales se realizó tu aborto?
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              
              <div className="row-check-2 row">
                <div className="row-check-2__col col-12">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="causal1" value="causal1 " onChange={verifyFields}/>
                    <label className="form-check-label" htmlFor="causal1">
                    <strong>Causal 1:</strong> Riesgo de vida
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-12">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="causal2" value="causal2" onChange={verifyFields}/>
                    <label className="form-check-label" htmlFor="causal2">
                    <strong>Causal 2:</strong> Inviabilidad fetal
                    </label>
                  </div>
                </div>
               
                <div className="row-check-2__col col-12">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="causal4" value="causal4" onChange={verifyFields}/>
                    <label className="form-check-label" htmlFor="causal4">
                    <strong>Causal 3:</strong> Violencia sexual
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-12">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="causal5" value="causal5" onChange={verifyFields}/>
                    <label className="form-check-label" htmlFor="causal5">
                   <strong> Causal 4:</strong> Nombre
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-12">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="causal6" value="causal6" onChange={verifyFields}/>
                    <label className="form-check-label" htmlFor="causal6">
                   <strong> Causal 5:</strong> Nombre
                    </label>
                  </div>
                </div>
              </div>
             
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
              Nombre de servicio de salud donde se realizó el procedimiento 
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Nombre de servicio de salud donde se realizó el procedimiento
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <input className="form-control" placeholder="Escribe aquí" />
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
              ¿En qué comuna se encuentra?
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Nombre de servicio de salud donde se realizó el procedimiento
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <input className="form-control" placeholder="Escribe aquí" />
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
              ¿Qué procedimiento(s) te realizaron? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     ¿Qué procedimiento(s) te realizaron?
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <p className="text-gray600">Selecciona las opciones que correspondan</p>
              <div className="row-check-2 row">
                <div className="row-check-2__col col-12">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="ameu" value="ameu" onChange={verifyFields}/>
                    <label className="form-check-label" htmlFor="ameu">
                    AMEU (Aspiración Manual Endouterina)
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-12">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="aborto" value="aborto" onChange={verifyFields}/>
                    <label className="form-check-label" htmlFor="aborto">
                    Aborto con medicamentos (Mifepristona y/o Misoprostol)
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-12">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="induccion" value="induccion" onChange={verifyFields}/>
                    <label className="form-check-label" htmlFor="induccion">
                    Inducción de parto por rotura de membranas
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-12">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="cesarea" value="cesarea" onChange={verifyFields}/>
                    <label className="form-check-label" htmlFor="cesarea">
                    Cesárea
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-12">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="legrado" value="legrado" onChange={verifyFields}/>
                    <label className="form-check-label" htmlFor="legrado">
                    Legrado o Raspaje
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-12">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="no-lo-se" value="no-lo-se" onChange={verifyFields}/>
                    <label className="form-check-label" htmlFor="no-lo-se">
                    No lo sé
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-12">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="metodoComprobacion" id="otro" value="otro"/>
                    <label className="form-check-label" htmlFor="otro">
                    Otro
                    </label>
                  </div>
                </div>
              </div>
             
            </div>
              <div className="form-row  form--last">
                <button
                  type="submit"
                  className="btn btn--type1"
                  disabled={!formularioValido}
                >Siguiente</button>
              </div>
            </form>
          </div>
        </div>
       
      </div>
    </>
  );
};

export default Step4Component;
