

// Función de ayuda para la fortaleza de la contraseña
const getPasswordStrength = (password: string) => {
  let strength = 0;
  if (password.length >= 8 && password.length <= 15) strength++;
  if (/\d/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password))
    strength++;
  if (/[A-Z]/.test(password)) strength++;
  return strength; // 0 = débil, 1 = aceptable, 2 = fuerte
};


export { getPasswordStrength };
