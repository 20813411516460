import React from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import CardWrapperComponent from '../../../components/Cards/cardWrapp.component';
import cards from '../../../utils/cardsAcompanamiento.json';
import ChatboxComponent from '../../../components/Process/Chatbox/chatBox.component';

interface Props {
  // Define your component props here
}

const AcompanamientoScreen: React.FC<Props> = () => {
  const steps = [
    { title: "Completa tu perfil", description: "Registrada", status: "status--success", completed: true, color: "green350" },
    { title: "Te presentamos a tu acompañante", description: "En proceso", status: "status--process", completed: false, color: "pink400" },
    { title: "Llamada telefónica", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Accede a la ley 21.030", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Seguimos en contacto", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Déjanos tu opinión", description: "Pendiente", status: "", completed: false, color: "gray600" },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];
  return (
    <>
      <HeaderShared />
      <section className="module-top container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom40 row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            
            <div className="module-bottom40 row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} /> 
              </div>
            
            </div>
            <ChatboxComponent
                title="Tu acompañante asignada"
                subtitle="Te presentamos a quién te estará acompañando durante todo tu proceso "
                chatUrl="/chat-acompanante"
                companionName="Antonia"
                companionRole="Acompañante"
              />
           
          </div>
        </div>
      </section>
      <CardWrapperComponent color="base" title="¿Qué hace una acompañante?" cards={cards} />
      <FooterShared />
    </>
  );
};

export default AcompanamientoScreen;