import React, {useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import FooterShared from '../../../shared/footer.shared';
import StepperComponent from '../../../components/Stepper/stepper.component';
import SexualidadEmocionesComponent from '../../../components/Auth/SexualidadEmociones/sexualidadEmociones.component';

const SexualidadEmocionesScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeStep, setActiveStep] = useState(6); // Estado para el paso actual
  return (
    <>
    <HeaderShared />
    <section className="module container">
      <div className="body-row row">
        <div className="col-12">
          <div className="module-bottom row">
            <div className="col-12">
              <PrivacyComponent />
            </div> 
          </div>
          <div className="auth-row module-bottom row">
            <div className="module-bottom40 col-12">
            <StepperComponent activeStep={activeStep} />
            </div>
            <div className="col-lg-5 col-12">
              <h1>Sexualidad y Emociones</h1>
              <article className="text-20 col-12 col-reset">
                <p className="text-green350">Quédate tranquila, tus datos siempre están protegidos.</p>
                <p>Estas preguntas <strong>NO son de carácter obligatorio</strong>, sin embargo nos pueden servir para ofrecerte otras alternativas. Recuerda que estás en un <strong>lugar</strong> seguro y vamos a proteger toda tu información.</p>
                  <p>Recuerda que tienes <strong>derecho a un procedimiento seguro</strong>, informado y libre de prejuicios.</p>
              </article>
              <SexualidadEmocionesComponent />
              
            </div>
            <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
          </div>
          
        </div>
      </div>
    </section>
    <FooterShared />
  </>
  );
};

export default SexualidadEmocionesScreen;