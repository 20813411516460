import React, {useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import StepperComponent from '../../../components/Stepper/stepper.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import IncidenciasComponent from '../../../components/Auth/Incidencias/incidencias.component';

const IncidenciasScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeStep, setActiveStep] = useState(7); // Estado para el paso actual
  return (
    <>
    <HeaderShared />
    <section className="module container">
      <div className="body-row row">
        <div className="col-12">
          <div className="module-bottom row">
            <div className="col-12">
              <PrivacyComponent />
            </div> 
          </div>
          <div className="auth-row module-bottom row">
            <div className="module-bottom40 col-12">
            <StepperComponent activeStep={activeStep} />
            </div>
            <div className="col-lg-5 col-12">
              <h1>Información de Incidencia y Comunicaciones</h1>
              <article className="text-20 col-12 col-reset">
                <p className="text-green350">Quédate tranquila, tus datos siempre están protegidos.</p>
                <p><strong>OLA está comprometida</strong> con la apertura de caminos para el acceso al aborto en Chile, estamos interesadas en comprender cuánto se conoce sobre el aborto, el aborto <strong>con medicamentos</strong>, la accesibilidad al aborto y la disponibilidad de información segura.</p>

                <p>Juntas derribamos barreras.</p>
              </article>
              <IncidenciasComponent />
            </div>
            <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
          </div>
          
        </div>
      </div>
    </section>
    <FooterShared />
  </>
  );
};

export default IncidenciasScreen;