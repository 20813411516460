import React, {useState, useEffect} from 'react';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EvaluationCardComponent from '../../Form/EvaluationCard/evaluationCard.component';

const EvaluacionHospitalComponent: React.FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formularioValido, setFormularioValido] = useState(false);

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    let todosCompletos = true;
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        // Verificar si al menos uno del grupo está seleccionado
        const grupoSeleccionado = !!document.querySelector(`input[name="${campo.name}"]:checked`);
        todosCompletos = todosCompletos && grupoSeleccionado;
      } else {
        const campoCompleto = campo.value.trim() !== '';
        todosCompletos = todosCompletos && campoCompleto;
      }
    });
  
    setFormularioValido(todosCompletos);
  }
  
    

  useEffect(() => {
    verifyFields();
  }, []);


  const handleSubmit = () => {
    navigate('/seguimiento');
  };

  return (
    <>
   
      <div className="row">
        <div className="col-md-5 col-12">
          <h2 className="text-green900 text-40 mb-3">Compártenos tu opinión acerca de la atención en el servicio de salud</h2>
          <article className="text-20">
            
         
            <p className="text-green350"><strong>Recuerda que este proceso, así como tus datos están protegidos.</strong></p>

          </article>
          <div className="form-block">
            <form onSubmit={handleSubmit}>
            <div className="form-row">
              <EvaluationCardComponent name="Hospital Dr. Juan Noé Crevanni" region="Atacama" /> 
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
              ¿La atención que recibiste del personal médico fue respetuosa? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                    ¿La atención que recibiste del personal médico fue respetuosa?
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-block">
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="atencion-respetuosa" id="si" value="si" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="si">
                    Sí
                  </label>
                </div>
              
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="atencion-respetuosa" id="siMuchas" value="siMuchas" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="siMuchas">
                  Sí, muchas veces
                  </label>
                </div>
              
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="atencion-respetuosa" id="casiNunca" value="casiNunca" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="casiNunca">
                  Casi nunca
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="atencion-respetuosa" id="nunca" value="nunca" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="nunca">
                  Nunca
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="atencion-respetuosa" id="prefieroNo" value="prefieroNo" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="prefieroNo">
                  Prefiero no hablar de esto
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="atencion-respetuosa" id="noLoSe" value="noLoSe" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="noLoSe">
                  No lo sé o no estoy segura
                  </label>
                </div>
              
              </div>
            </div>
           
            <div className="form-row">
              <label className="form-label label--icon">
              ¿En el servicio de salud, ¿Se te indicó claramente sobre las etapas y procedimientos asociados a la Interrupción Voluntaria del Embarazo (IVE)? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                    ¿La atención que recibiste del personal médico fue respetuosa?
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-block">
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="servicio-salud" id="si" value="si" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="si">
                  Sí, siempre
                  </label>
                </div>
              
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="servicio-salud" id="siMuchas" value="siMuchas" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="siMuchas">
                  Sí, muchas veces
                  </label>
                </div>
              
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="servicio-salud" id="aveces" value="aveces" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="aveces">
                  A veces
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="servicio-salud" id="casiNunca" value="casiNunca" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="casiNunca">
                  Casi nunca
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="servicio-salud" id="nunca" value="nunca" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="nunca">
                  Nunca
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="servicio-salud" id="prefieroNo" value="prefieroNo" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="prefieroNo">
                  Prefiero no hablar de esto
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="servicio-salud" id="noLoSe" value="noLoSe" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="noLoSe">
                  No lo sé o no estoy segura
                  </label>
                </div>
              
              </div>
            </div>
           
            <div className="form-row">
              <label className="form-label label--icon">
              ¿Sufriste algún tipo de maltrato o discriminación durante las etapas y procedimientos asociados a la Interrupción Voluntaria del Embarazo (IVE)? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     ¿Sufriste algún tipo de maltrato o discriminación durante las etapas y procedimientos asociados a la Interrupción Voluntaria del Embarazo (IVE)?
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-block">
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="maltrato" id="si" value="si" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="si">
                  Sí, siempre
                  </label>
                </div>
              
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="maltrato" id="siMuchas" value="siMuchas" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="siMuchas">
                  Sí, muchas veces
                  </label>
                </div>
              
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="maltrato" id="aveces" value="aveces" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="aveces">
                  A veces
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="maltrato" id="casiNunca" value="casiNunca" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="casiNunca">
                  Casi nunca
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="maltrato" id="nunca" value="nunca" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="nunca">
                  Nunca
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="maltrato" id="prefieroNo" value="prefieroNo" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="prefieroNo">
                  Prefiero no hablar de esto
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input requiredField" type="radio" name="maltrato" id="noLoSe" value="noLoSe" onChange={verifyFields} />
                  <label className="form-check-label" htmlFor="noLoSe">
                  No lo sé o no estoy segura
                  </label>
                </div>
              
              </div>
            </div>
           
            <div className="form-row">
              <label className="form-label label--icon">
              ¿El procedimiento que te hicieron se hizo respetando tu bienestar? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     ¿El procedimiento que te hicieron se hizo respetando tu bienestar?
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="procedimiento" id="procedimientoSi" value="si"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="procedimientoSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="procedimiento" id="procedimientoNo" value="no"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="procedimientoNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="procedimiento" id="procedimientoNoSeguro" value="noLoSe"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="procedimientoNoSeguro">
                    No lo sé
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
              ¿Pudiste realizar la interrupción Voluntaria del Embarazo en el servicio de salud correspondiente a tu domicilio actual o convenio de salud? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     ¿Pudiste realizar la interrupción Voluntaria del Embarazo en el servicio de salud correspondiente a tu domicilio actual o convenio de salud?
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="interrupcion" id="interrupcionSi" value="si"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="interrupcionSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="interrupcion" id="interrupcionNo" value="no"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="interrupcionNo">
                    No
                  </label>
                </div>
               
              </div>
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
              ¿Después del procedimiento, ¿se te dieron todas las indicaciones a seguir para tu cuidado? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     ¿Después del procedimiento, ¿se te dieron todas las indicaciones a seguir para tu cuidado?
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="indicaciones" id="indicacionesSi" value="si"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="indicacionesSi">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="indicaciones" id="indicacionesNo" value="no"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="indicacionesNo">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="indicaciones" id="indicacionesNoTodas" value="NoTodas"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="indicacionesNoTodas">
                    No todas
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="indicaciones" id="indicacionesNoSegura" value="NoSegura"  onChange={verifyFields}/>
                  <label className="form-check-label" htmlFor="indicacionesNoSegura">
                    No estoy segura
                  </label>
                </div>
               
              </div>
            </div>
            <div className="form-row">
              <Alert variant='success' className="alert--soft-danger alert--complete">
                  <div className="col-12">
                  <i className="icon fa-regular fa-flag"></i>
                    <article>
                      <p>Si consideras que la atención que recibiste <strong>no fue adecuada</strong> o no se <strong>respetó tu bienestar</strong>, puedes conversar con tu acompañante para que te oriente en la realización de una <strong>denuncia</strong> o <strong>reclamo</strong> al <strong>Servicio de Salud</strong>.</p>
                    </article>
                  </div>
                </Alert>
            </div>

            <div className="form-row">
            <label className="form-label label--icon">
            ¿Hay algo más que te gustaría comentar? <small className="required">*</small>
              <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                    ¿Hay algo más que te gustaría comentar?
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <textarea className="form-control" id="otherUrgencia" rows={3} placeholder='Siéntete libre de contarnos cualquier cosa'  onChange={verifyFields}></textarea>
            </div>

              <div className="form-row  form--last">
                <button
                  type="submit"
                  className="btn btn--type1"
                  disabled={!formularioValido}
                >Siguiente</button>
              </div>
            </form>
          </div>
        </div>
        <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-end">
          <figure className="image-350">
            <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
          </figure>
        </aside>
      </div>
    </>
  );
};

export default EvaluacionHospitalComponent;
