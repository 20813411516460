import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import CheckerBannerComponent from '../../../../Form/CheckerBanner/checkerBanner.component';


const CausalCard2Component: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [causalData, setCausalData] = useState({
    number: '',
    text: '',
    isChecked: false
  });

  const handleCausalChange = (number: string | undefined, text: string, isChecked: boolean) => {
    const actualNumber = number || ''; // Usar cadena vacía si number es undefined
    setCausalData({ number: actualNumber, text, isChecked });
  };
  
  

  return (
    <div className="causal-card card--color1">
        <h3 className="text-regular">Causal 2</h3>
        <article>
          <p className="text-20 text-700">Patología congénita adquirida o genética, incompatible con la vida extrauterina independiente en todo caso de carácter letal.</p>
          <p>La <strong>Ley Nº 21.030</strong> señala que se autoriza la interrupción voluntaria del embarazo cuando el embrión o feto padezca una patología congénita adquirida o genética, incompatible con la vida extrauterina independiente, en todo caso de carácter letal. </p>
         <div className="mt-4 text-green400 text-700 col-12">
          <p className="text-end mb-0"><em>Quiero saber más</em></p>
         </div>
        </article>
        <Accordion className="card-accordeon">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Listado de patologías</Accordion.Header>
            <Accordion.Body>
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="1"
                text={<span>Anenecefalia, exencefalia, acráneo. Craneo raquisquisis, iniencefalia y hidranencefalia.</span>}
                value="Anenecefalia, exencefalia, acráneo. Craneo raquisquisis, iniencefalia y hidranencefalia"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="2"
                text={<span>Holoproscenfalia alobar.</span>}
                value="Holoproscenfalia alobar"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="3"
                text={<span>Atresia laríngea o traqueal.</span>}
                value="Atresia laríngea o traqueal"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="4"
                text={<span><strong>Pentalogía de cantrell:</strong> corazón fuera de la cavidad torácica, defectos en la pared abdominal. </span>}
                value="Pentalogía de cantrell: corazón fuera de la cavidad torácica, defectos en la pared abdominal"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="5"
                text={<span>Secuencia de tallo corporal. Defecto en la pared abdominal que genera hipoplasia pulmonar.</span>}
                value="Secuencia de tallo corporal. Defecto en la pared abdominal que genera hipoplasia pulmonar"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="6"
                text={<span><strong>Agenesia renal bilateral:</strong> ausencia del desarrollo de los riñones, falta de un sistema excretor. </span>}
                value="Agenesia renal bilateral: ausencia del desarrollo de los riñones, falta de un sistema excretor"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="7"
                text={<span>Riñones multi o poliquísticos con secuencia Potter y de inicio precoz.</span>}
                value="Riñones multi o poliquísticos con secuencia Potter y de inicio precoz"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="8"
                text={<span>Displasia esquelética con hipoplasia torácica y pulmonar.  Displasia tanatofórica tipo I y II. Ostogenesis imperfecta tipo ll.</span>}
                value="Displasia esquelética con hipoplasia torácica y pulmonar.  Displasia tanatofórica tipo I y II. Ostogenesis imperfecta tipo ll"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="9"
                text={<span>Alteraciones cromosómicas incompatibles con la vida. Trisomía 18, 13, 9 y las triploidías.</span>}
                value="Alteraciones cromosómicas incompatibles con la vida. Trisomía 18, 13, 9 y las triploidías"
              />
              <CheckerBannerComponent
                onChange={handleCausalChange}
                number="10"
                text={<span>Gemelos acárdicos. Ausencia de la formación del corazón de uno de los gemelos monocigóticos.</span>}
                value="Gemelos acárdicos. Ausencia de la formación del corazón de uno de los gemelos monocigóticos"
              />
              
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
    </div>
  );
};

export default CausalCard2Component;
