import React from 'react';


interface ChatBoxProps {
  title: string;
  subtitle: string;
  chatUrl: string;
  companionName: string;
  companionRole: string;
}



const ChatboxComponent: React.FC<ChatBoxProps> = ({ 
  title,
  subtitle,
  chatUrl,
  companionName,
  companionRole
}) => {
  return (
    <>
       <div className="module-bottom row">
          <div className="col-md-10 col-12 mx-auto">
            <article className="text-center module-bottom40">
              <h2 className="text-52 mb-4">{title}</h2>
              <p className="text-24">{subtitle}</p>
            </article>
            <div className="chatbox-info">
              <div className="chatbox-info__row row">
                <div className="chatbox-info__text col-lg-6 col-12">
                  <ul className="list-checker">
                    <li>
                      <span className="icon icon--success material-icons-outlined">check_circle</span>
                      <article>
                        <p><strong>Chat multicanal</strong></p>
                        <p className="text-gray600">Podrás revisar el chat con tu acompañante por aquí o por WhatsApp.</p>
                      </article>
                    </li>
                    <li>
                      <span className="icon icon--success material-icons-outlined">check_circle</span>
                      <article>
                        <p><strong>Atención 24/7 </strong></p>
                        <p className="text-gray600">Cualquier duda quedará resuelta en cualquier día de la semana. Tu acompañante estará disponible tan pronto sea posible.</p>
                      </article>
                    </li>
                    <li>
                      <span className="icon icon--success material-icons-outlined">check_circle</span>
                      <article>
                        <p><strong>Conversaciones protegidas</strong></p>
                        <p className="text-gray600">El chat que tengas con tu acompañante estará protegido y encriptado para que todo lo que menciones quede en estricta confidencialidad. ¡Estás segura! </p>
                      </article>
                    </li>
                  </ul>
                </div>
                <aside className="chatbox-info__module col-lg-6 col-12">
                    <div className="chatbox-action">
                      <div className="chatbox-action__top">
                        <div className="chatbox-action__user">
                          <figure>
                            <img src="/assets/images/icons/user-pic-2.svg" alt={companionName} />
                            
                          </figure>
                          <article>
                            <h4>{companionName}</h4>
                            <p className="text-20 text-gray600">{companionRole}</p>
                          </article>
                        </div>
                        <a href={chatUrl} className="btn btn--chat">Escríbeme <i className="icon icon--chat"></i></a>
                      </div>
                      <div className="chatbox-action__bottom">
                        <p className="text-black">“¡Hola! Soy Antonia y estoy aquí para acompañarte durante todo este proceso”</p>
                      </div>
                    </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
       
    </>
  );
};

export default ChatboxComponent;
