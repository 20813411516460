import React, { useState, useEffect } from 'react';
import HospitalCard from './hospitalCard.component';
import hospitalesData from '../../utils/hospitales.json';
import { WithContext as ReactTags } from 'react-tag-input';

declare global {
  interface Window {
    google: unknown;
  }
}
interface Tag {
  id: string;
  text: string;
}


interface Props {
  // Define the props for your component here
}

interface Hospital {
  id: number;
  name: string;
  lat: number;
  long: number;
  address: string;
  service: string;
  phone: string[];
  review: string;
}


const HospitalListComponent: React.FC<Props> = () => {
  const initialResultsToShow = 5;
  const [resultsToShow, setResultsToShow] = useState(initialResultsToShow);
  const hospitales: Hospital[] = hospitalesData.hospitales as Hospital[];
  const [tags, setTags] = useState<Tag[]>([]);
  const suggestions: Tag[] = hospitales.flatMap((hospital) => [
    { id: `${hospital.id}-name`, text: hospital.name },
    { id: `${hospital.id}-service`, text: hospital.service },
  ]);
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hospitalesFiltrados, setHospitalesFiltrados] = useState<Hospital[]>(hospitales.slice(0, initialResultsToShow));
  const [isFiltering, setIsFiltering] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = (i: number) => {
    const newTags = tags.slice(0);
    newTags.splice(i, 1);
    setTags(newTags);
  
    if (newTags.length > 0) {
      const filteredHospitales = hospitales.filter((hospital) => {
        return newTags.some((tag) => {
          return (
            hospital.name.toLowerCase().includes(tag.text.toLowerCase()) ||
            hospital.service.toLowerCase().includes(tag.text.toLowerCase())
          );
        });
      });
      setHospitalesFiltrados(filteredHospitales.slice(0, initialResultsToShow));
    } else {
      setHospitalesFiltrados(hospitales.slice(0, initialResultsToShow));
    }
  };
  

  const handleAddition = (tag: Tag) => {
    setIsFiltering(true);
    setTags((prevTags) => {
      const newTags = [...prevTags, tag];
    
      const filteredHospitales = hospitales.filter((hospital) => {
        return newTags.some((tag) => {
          return (
            hospital.name.toLowerCase().includes(tag.text.toLowerCase()) ||
            hospital.service.toLowerCase().includes(tag.text.toLowerCase())
          );
        });
      });
  
      setHospitalesFiltrados(filteredHospitales.slice(0, initialResultsToShow));
      addHospitalMarkers(filteredHospitales.slice(0, initialResultsToShow));
  
      return newTags;
    });
  };
  
  
  

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addHospitalMarkers = (hospitales: Hospital[]) => {
    const mapElement = document.getElementById("map");
    if (mapElement) {
      const map = new window.google.maps.Map(mapElement, {
        center: { lat: -35, lng: -71 },
        zoom: 6,
      });
  
      hospitales.forEach((hospital) => {
        new window.google.maps.Marker({
          position: { lat: hospital.lat, lng: hospital.long },
          map: map,
          title: hospital.name,
        });
      });
    }
  };

  const handleTagClick = (index: number) => {
    console.log('index')
    console.log(index)
    
  };
  

  const handleShowMore = () => {
    setResultsToShow(prevResultsToShow => {
      const newResultsToShow = prevResultsToShow + 5;
      const newHospitalesFiltrados = hospitales.slice(0, newResultsToShow);
      setHospitalesFiltrados(newHospitalesFiltrados);
      return newResultsToShow;
    });
  };
  

  const handleShowLess = () => {
    setResultsToShow(initialResultsToShow);
    const newHospitalesFiltrados = hospitales.slice(0, initialResultsToShow);
    setHospitalesFiltrados(newHospitalesFiltrados);
  };
  

  useEffect(() => {
    const initMap = () => {
      const mapElement = document.getElementById("map");
      if (mapElement) {
        const map = new window.google.maps.Map(mapElement, {
          center: { lat: -35, lng: -71 },
          zoom: 6,
        });

        const addHospitalMarkers = (hospitales: Hospital[]) => {
          hospitales.forEach((hospital) => {
            new window.google.maps.Marker({
              position: { lat: hospital.lat, lng: hospital.long },
              map: map,
              title: hospital.name,
            });
          });
        };

        addHospitalMarkers(hospitales.slice(0, resultsToShow));
      }
    };

    if (window.google) {
      initMap();
    }
  }, [resultsToShow, hospitales]);

  const handleButtonClick = () => {
    setIsOpen(!isOpen); // Cambia el estado al opuesto de lo que está actualmente
  };

  return (
    <section className="hospital-wrapp">
      <div className="row">
        <div className="hospital-wrapp__search col-lg-8 col-12 mx-auto">
          <ReactTags
              tags={tags}
              suggestions={suggestions}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleTagClick={handleTagClick}
              allowDragDrop={false}
              inputFieldPosition="top"
              autocomplete
              placeholder='Busca un hospital por comuna, palabra clave o región'
            />
        </div>
        <div className="hospital-wrapp__show col-12 mb-5">
          <button type="button" className="btn btn--type2 btn--100"  onClick={handleButtonClick}>
            Ver mapa
          </button>
        </div>
        <div className="col-lg-6 col-12">
        {hospitalesFiltrados.map((hospital, index) => ( // Mostrar los hospitales filtrados
            <HospitalCard
              key={index}
              id={hospital.id}
              name={hospital.name}
              service={hospital.service}
              address={hospital.address}
              phone={[hospital.phone[0]]}
              review={hospital.review}
              lat={hospital.lat}
              long={hospital.long}
            />
          ))}
          <div className="d-flex justify-content-center mt-5">
          {!isFiltering && (
            resultsToShow < hospitales.length ? (
              <button type="button" className="btn btn--type2 btn--icon" onClick={handleShowMore}>
                <span className="icon material-icons-outlined">add</span> Mostrar más resultados
              </button>
            ) : (
              <button type="button" className="btn btn--type2 btn--icon" onClick={handleShowLess}>
                <span className="icon material-icons-outlined">remove</span> Mostrar menos resultados
              </button>
            )
          )}

         
          </div>
          <div className="d-flex justify-content-center mt-5">
            <a href="/gestion-aprobacion" className="btn btn--type1">Confirmar selección</a>
          </div>
        </div>
        <aside className={`map-side col-lg-6 col-12 ${isOpen ? 'active' : ''}`}>
          <button type="button" className="btn btn--close"  onClick={handleButtonClick}><span className="material-icons-outlined">close</span></button>
          <div id="map" style={{ height: "400px" }}></div>
        </aside>
      </div>
    </section>
  );
};

export default HospitalListComponent;