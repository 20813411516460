import React, { useState } from 'react';
import CheckerBannerComponent from '../../../../Form/CheckerBanner/checkerBanner.component';


const CausalCard3Component: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [causalData, setCausalData] = useState({
    number: '',
    text: '',
    isChecked: false
  });

  const handleCausalChange = (number: string | undefined, text: string, isChecked: boolean) => {
    const actualNumber = number || ''; // Usar cadena vacía si number es undefined
    setCausalData({ number: actualNumber, text, isChecked });
  };
  

  return (
    <div className="causal-card card--color2">
        <h3 className="text-regular">Causal 3</h3>
        <article>
          <p className="text-20 text-purple300 text-700">Embarazo resultado de una violación.</p>
          <p>El embarazo sea resultado de una violación, siempre que no hayan transcurrido más de <strong>12 semanas + 6 días de gestación</strong>. Tratándose de una niña <strong>menor de 14 años</strong>, la interrupción del embarazo podrá realizarse siempre que no hayan transcurrido más de <strong>14 semanas + 6 días de gestación</strong>.</p>
          <p>Recuerda que:</p>
          <ul>
            <li>No es requisito hacer denuncia al agresor o agresores</li>
            <li>Edad de Consentimiento Sexual en Chile es de 14 años. Antes de esa edad, toda relación sexual está incluida en la causal número 3.</li>
            <li>Mayor de 14 años y menor de 18 años. NO requiere la autorización de su representante legal, pero debe ser informado sobre la decisión de la mujer.</li>
          </ul>
          <CheckerBannerComponent
            onChange={handleCausalChange}
            number="1"
            text={<span>Entiendo, esta es mi causal</span>}
            value="Entendido"
          />
        </article>
       
    </div>
  );
};

export default CausalCard3Component;
